import {Component, OnInit}                  from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Title}                              from '@angular/platform-browser';
import {ActivatedRoute, Router}             from '@angular/router';
import {NgxSpinnerService}                  from 'ngx-spinner';
import {ToastrService}                      from 'ngx-toastr';
import Swal                                 from 'sweetalert2/dist/sweetalert2.js';
import {LoginService}                       from '../../shared/services/login.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['../../login/login-background.scss']
})
export class ResetPasswordComponent implements OnInit
{
    public token: string;
    public email: string;
    public resetPasswordForm: FormGroup<any> = this.formBuilder.group({
            Password: ['', [Validators.required, Validators.minLength(5)]],
            ConfirmPassword: ['', [Validators.required, Validators.minLength(5)]],
        }, {validator: this.checkPasswords}
    );

    public constructor(
        public toastr: ToastrService,
        private formBuilder: FormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private loginService: LoginService,
        private spinnerService: NgxSpinnerService
    )
    {
    }

    public get Password()
    {
        return this.resetPasswordForm.get('Password');
    }

    public get ConfirmPassword()
    {
        return this.resetPasswordForm.get('ConfirmPassword');
    }

    public checkPasswords(group: FormGroup)
    {
        const pass = group.controls.Password.value;
        const confirmPass = group.controls.ConfirmPassword.value;
        return pass === confirmPass ? null : {notSame: true};
    }

    public ngOnInit(): void
    {
        this.titleService.setTitle('MTA Plus - ' + 'Re-Set Password');
        this.activatedRoute.queryParams.subscribe(params => {
            this.email = params.email;
            this.token = params.code;
        });
    }

    public changePassClick(): void
    {
        if (this.resetPasswordForm.valid) {
            const data = Object.assign(this.resetPasswordForm.value, {
                Email: this.email,
                Token: this.token
            });

            this.loginService.changePassword(data).subscribe(
                response => {
                    this.toastr.success(response.message, 'Success!', {tapToDismiss: true});
                    this.spinnerService.hide();
                    this.router.navigateByUrl('login');
                },
                error => this.PasswordRequestError(error)
            );

            this.spinnerService.show();
        } else {
            this.toastr.error('Passwords do not match.', 'Validation Error');
        }
    }

    public PasswordRequestError(res): void
    {
        this.toastr.error(res.error, 'Server Error');
        this.RequestSuccess();
    }

    public RequestSuccess(): void
    {
        this.router.navigateByUrl('login');
        this.spinnerService.hide();
        Swal.fire({
            title: 'New Email Sent',
            html: 'A new password reset email has been sent to ' + this.email.trim() + ', please use this new link.',
            icon: 'warning',
            confirmButtonText: 'Continue'
        });
    }
}
