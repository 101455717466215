<div class="wrapper">
    <div class="authentication-header area">
        <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>

    <div class="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
        <div class="container-fluid">
            <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                <div class="col mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <div class="p-4 rounded">
                                <div class="text-center">
                                    <img alt="" src="assets/images/MTALogoNoBackground-01.png" style="width: 25em;" />
                                    <h3 class="">Sign in</h3>
                                    <p>Don't have an account yet? <a routerLink="/register">Sign up here</a>
                                    </p>
                                </div>

                                <div class="form-body">
                                    <form [formGroup]="loginForm" class="row g-3">
                                        <div class="col-12">
                                            <label class="form-label" for="inputEmailAddress">Email Address</label>
                                            <input class="form-control" formControlName="Email" id="inputEmailAddress" placeholder="Email Address" type="email">
                                            <div *ngIf="loginForm.controls.Email.errors && (loginForm.controls.Email.dirty || loginForm.controls.Email.touched) " class="text-danger">
                                                <div *ngIf="loginForm.controls.Email.errors.required && submit == true">
                                                    * Please enter email Address
                                                </div>
                                                <div *ngIf="loginForm.controls.Email.errors.pattern && submit == true">
                                                    * Please enter valid email address
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <label class="form-label" for="inputChoosePassword">Enter Password</label>
                                            <div class="input-group" id="show_hide_password">
                                                <input class="form-control border-end-0" formControlName="Password" id="inputChoosePassword" placeholder="Enter Password" type="{{passType}}" value="12345678"> <a (click)="passType == 'password' ? passType = 'text' : passType = 'password'" class="input-group-text bg-transparent"><i class="bx bx-hide"></i></a>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="d-grid">
                                                <button (click)="OnLoginClick()" class="btn btn-primary"><i class="bx bxs-lock-open"></i>Sign in</button>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-check form-switch">
                                                <input checked class="form-check-input" id="flexSwitchCheckChecked" type="checkbox">
                                                <label class="form-check-label" for="flexSwitchCheckChecked">Remember Me</label>
                                            </div>
                                        </div>

                                        <div class="col-md-6 text-end">
                                            <a routerLink="/forgot-password">Forgot Password ?</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
