import {Component}         from '@angular/core';
import {Router}            from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {GlobalService}     from './global.service';
import {LoginService}      from './shared/services/login.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent
{
    public title = 'MTA-Plus';
    public baseUrl: string = '';
    public code: any = [];
    public org: any;

    public constructor(
        private router: Router,
        private loginService: LoginService,
        private spinner: NgxSpinnerService,
        private globalServices: GlobalService
    )
    {
        const url = window.location.href;
        const i = window.location.href.indexOf('?');
        const j = window.location.href.length;
        this.code = url.substr(i - j + 1).split('&');

    }

    public ngOnInit(): void
    {
    }

    public ValidateRequestSuccess(res: any): void
    {
        if (res.message === 'Access token generated successfully.') {
            this.globalServices.setAccessToken(res.accessToken.TokenKey, res.accessToken.TokenSecret);
            this.router.navigateByUrl('/layout/contacts');
            this.spinner.hide();
        }
    }

    public ValidateRequestError(res)
    {
    }
}
