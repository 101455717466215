<section class="w-100 float-left mh">
	<header class="header w-100 float-left pt-2">
		<div class="logo">
			<a href="login"> <img src="../../assets/images/logo-icon.png" alt="logo" /></a>
		</div>
	</header>
	<div class="noheader clearfix">
		<div class="row">
			<div class="col-3" *ngFor="let user of additionalUsers">
				<img *ngIf="user.company.companyLogo" [src]="user.company.companyLogo" style="max-width:300px;max-height:300px" />
				<h5>{{user.company.company_Name}}</h5>
				<h6>User Details</h6>
				<ul>
					<li>
						<strong>Name:</strong> {{user.fullName}}
					</li>
					<li>
						<strong>Email (virtual):</strong> {{user.email}}
					</li>
					<li>
						<strong>Role:</strong> {{user.role?.role_Name ? user.role.role_Name : getUserRole()}}
					</li>
				</ul>
				<button class="btn btn-primary" (click)="selectCompany(user)">Select Company</button>
			</div>
		</div>
	</div>
</section>