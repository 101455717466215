<div class="wrapper">
    <div class="authentication-header area">
        <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>

    <div class="authentication-forgot d-flex align-items-center justify-content-center my-5 my-lg-0">
        <div class="container-fluid">
            <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                <div class="col mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <div class="p-4 rounded">
                                <div class="text-center">
                                    <span class="fa-regular fa-unlock" style="color: #229ca9; font-size: 7em;"></span>
                                </div>

                                <h4 class="mt-5 font-weight-bold">Reset Password</h4>
                                <p class="text-muted">Change your password with this form, it should be unique and hard to guess</p>

                                <form #ngForm="ngForm" (ngSubmit)="changePassClick()" [formGroup]="resetPasswordForm">
                                    <div class="mb-3">
                                        <label class="form-label">New Password</label>
                                        <input [ngClass]="{'is-invalid' : Password.invalid && (Password.dirty || Password.touched || ngForm.submitted)}" class="form-control" formControlName="Password" id="password" type="password" />

                                        <div *ngIf="Password.errors?.['required'] && (Password.dirty || Password.touched || ngForm.submitted)" class="invalid-feedback">Please enter a new password.</div>
                                        <div *ngIf="Password.errors?.['minLength'] && (Password.dirty || Password.touched || ngForm.submitted)" class="invalid-feedback">Your password must contain at least 5 characters.</div>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label">Confirm Password</label>
                                        <input [ngClass]="{'is-invalid' : ConfirmPassword.invalid && (ConfirmPassword.dirty || ConfirmPassword.touched || ngForm.submitted)}" class="form-control" formControlName="ConfirmPassword" id="ConfirmPassword" type="password" />

                                        <div *ngIf="ConfirmPassword.errors?.['required'] && (ConfirmPassword.dirty || ConfirmPassword.touched || ngForm.submitted)" class="invalid-feedback">Please confirm your password.</div>
                                        <div *ngIf="ConfirmPassword.errors?.['minLength'] && (ConfirmPassword.dirty || ConfirmPassword.touched || ngForm.submitted)" class="invalid-feedback">Your password must contain at least 5 characters.</div>
                                    </div>

                                    <div class="col-12">
                                        <div class="d-grid">
                                            <button [disabled]="!resetPasswordForm.valid" class="btn btn-primary" type="submit">Reset Password</button>
                                        </div>
                                    </div>

                                    <div class="col-12 mt-3">
                                        <a routerLink="/login">Cancel</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
