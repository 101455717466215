import {Component}     from '@angular/core';
import {Router}        from '@angular/router';
import {GlobalService} from '../global.service';

@Component({
    selector: 'app-send-link',
    templateUrl: './send-link.component.html',
    styleUrls: ['./send-link.component.scss']
})
export class SendLinkComponent
{
    public EmailId: string;

    public constructor(
        private router: Router,
        private globalService: GlobalService
    )
    {
        this.EmailId = this.globalService.getEmail();
    }

    public resendPasswordResetLink(): void
    {
        this.router.navigateByUrl('/forgot-password');
    }
}
