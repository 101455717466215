<section class="w-100 float-left bg-white">
    <header class="header w-100 float-left pt-2">
        <div class="logo">
            <a href="login"> <img alt="logo" src="../../assets/images/logo-icon.png" /></a>
        </div>
    </header>

    <div class="login_form p-3 text-center" style="margin-top: 10%;">
        <img alt="logo" src="../../assets/images/emailpic.png" />
        <h1 style="font-weight: 700;">{{FirstName}}, an email is on its way</h1>
        <p>We sent it to {{Email}}  and need to make sure it reached you</p>
        <a class="btn" href="https://mail.google.com/" target="_blank">Go to inbox </a>
    </div>
</section>
