import {HttpClient}    from '@angular/common/http';
import {Injectable}    from '@angular/core';
import {Router}        from '@angular/router';
import {Observable}    from 'rxjs';
import {GlobalService} from '../../global.service';
import {RegisterModel} from '../models/Register';
import {UserModel}     from '../models/UserModel';
import {BaseService}   from './base.service';

@Injectable({
    providedIn: 'root'
})

export class LoginService extends BaseService
{
    public data: UserModel;

    public constructor(
        private http: HttpClient,
        public router: Router,
        private globalService: GlobalService
    )
    {
        super(router);
        this.data = new UserModel();
    }

    public ValidateClientDetails(Email: string, password: string): Observable<any>
    {
        const body = new URLSearchParams();
        body.set('grant_type', 'password');
        body.set('Email', Email);
        body.set('password', password);

        this.globalService.SetLoginTime(new Date());

        return this.http.post(this.apiUrl + '/ApplicationUser/Login', {Email, password}, {headers: {skip: 'true'}});
    }

    public GetUsers(): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        return this.http.get(this.apiUrl + '/ApplicationUser/GetUsers/' + companyId).pipe();
    }

    public GetRoleList(): Observable<any>
    {
        return this.http.get(this.apiUrl + '/Login/GetRoleList');
    }

    public forgotpasswordsend(email: string): Observable<any>
    {
        return this.http.get(this.apiUrl + '/Company/ForgotPassword/' + email);
    }

    public ValidateEmailToken(token: string): Observable<any>
    {
        return this.http.get(this.apiUrl + '/Login/Validatetoken/' + token);
    }

    public ValidateEmailConfirmationToken(data): Observable<any>
    {
        return this.http.post(this.apiUrl + '/Company/ConfirmEmail', data);
    }

    public ReSendEmailConfirmationToken(email): Observable<any>
    {
        return this.http.get(this.apiUrl + '/Company/ReSendEmailConfirmationToken/' + email);
    }

    public changePassword(data): Observable<any>
    {
        return this.http.post(this.apiUrl + '/Company/ResetPassword', data).pipe();
    }

    public setPassword(Password: string, Token: string): Observable<any>
    {
        const data = {Password, Token};
        return this.http.post(this.apiUrl + '/Login/setUserPassword', data).pipe();
    }

    public getLastLoginTime(username: string): Observable<any>
    {
        return this.http.get(this.apiUrl + '/Login/getLastLoginTime/' + username);
    }

    public setLastLogOutTime(username: string): Observable<any>
    {
        return this.http.get(this.apiUrl + '/Login/setLastLogOutTime/' + username);
    }

    public GetAccesstokenFromAPI(contactId: string): Observable<any>
    {
        return this.http.get(this.apiUrl + '/Login/GetAccesstoken' + '/' + contactId);
    }
    
    public SignUpClient(register: RegisterModel): Observable<any>
    {
        return this.http.post(this.apiUrl + '/Login/RegisterUser', register).pipe();
    }

    public Logout()
    {
        this.globalService.setUsersName('');
        this.globalService.setUsersID('');
        this.globalService.setUsersToken('');
        this.globalService.setCompanyName('');
        this.globalService.setCompanyID('');
        this.router.navigateByUrl('/login');
    }
}
