<h1 mat-dialog-title style="width: 100%;">Xero not connected</h1>
<div mat-dialog-content>
    <div class="row">
      <div class="col-md-6">
        <p>If you are having problems with data from Xero, please try re-connecting.</p>
        <img (click)="connectToXero()"
          src="../../../../assets/images/connect-blue.svg">
      </div>
    </div>
</div>

<div mat-dialog-actions>
  <div class="col-12">
    <p class="text-center">
      <button mat-button class="btn btn-dark ml-1 px-3" (click)="onNoClick(null)"><i class="fa fa-times"></i>
        Cancel</button>
    </p>
  </div>
</div>