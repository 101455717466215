import {Component, OnInit}      from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService}      from 'ngx-spinner';
import {ToastrService}          from 'ngx-toastr';
import {GlobalService}          from '../../global.service';
import {UserModel}              from '../../shared/models/UserModel';
import {LoginService}           from '../../shared/services/login.service';

@Component({
    selector: 'company-select',
    templateUrl: './company-select.component.html',
    styleUrls: ['./company-select.component.scss', '../register/register.component.scss']
})
export class CompanySelectComponent implements OnInit
{
    additionalUsers: UserModel[];
    password: string = '';

    constructor(
        public loginservice: LoginService,
        public globalService: GlobalService,
        private spinnerService: NgxSpinnerService,
        private Router: Router,
        private route: ActivatedRoute,
        public toastr: ToastrService,
    )
    {
        this.route.queryParams.subscribe(params => {
            console.log(params);
            this.password = atob(params['data']);
            console.log(this.password);
        });

    }

    ngOnInit()
    {
        this.additionalUsers = this.globalService.getMultiUser();
        this.spinnerService.hide();
    }

    getUserRole()
    {
        var role = this.globalService.getRole();
        console.log(role);
        return role.role_Name;
    }

    selectCompany(info)
    {
        console.log(info);
        this.loginservice.ValidateClientDetails(info.email, this.password).subscribe(res => {
                localStorage.setItem('title', 'Dashboard');
                this.Router.navigate(['/layout/dashboard']);
                console.log(res);
                this.globalService.setUsersName(res.fullName);
                this.globalService.setUsersID(res.id);
                this.globalService.setUsersToken(res.token);
                this.globalService.setCompanyName(res.company_Name);
                this.globalService.setCompanyID(res.companyID);
                this.globalService.setVehicleColumns(res.company.vehicleColumns);
                res.company.vehicleColumns = [];
                this.globalService.setCompanyDetails(res.company);
                this.globalService.setRole(res.role);
            },
            res => this.ValidateRequestError(res));

    }

    ValidateRequestError(res)
    {
        console.log(res);
        this.toastr.error(res.error.message, 'Error ' + res.status, {tapToDismiss: true});
        this.spinnerService.hide();
    }
}
