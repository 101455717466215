<section class="w-100 float-left mh">
    <div class="authentication-header area">
        <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>

    <div class="error-404 d-flex align-items-center justify-content-center">
        <div class="container">
            <div class="card py-5">
                <div class="row g-0">
                    <div class="col col-xl-6">
                        <div class="card-body p-5">
                            <h1 class="display-1"><span class="text-primary">4</span><span class="text-secondary">0</span><span class="text-primary">4</span></h1>
                            <h2 class="font-weight-bold display-4">Lost in Space</h2>
                            <p>You have reached the edge of the universe.
                                <br>The page you requested could not be found.
                                <br>Dont'worry and return to the previous page.</p>
                            <div class="mt-5"><a (click)="OnCancelClick()" class="btn btn-primary btn-lg px-md-5 radius-30">Go Home</a>
                                <a (click)="location.back()" class="btn btn-outline-dark btn-lg ms-3 px-md-5 radius-30">Back</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <img alt="" class="img-fluid" src="assets/images/404-animation.gif">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
