import {Component, OnInit}      from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import Swal           from 'sweetalert2/dist/sweetalert2.js';
import {LoginService} from '../../shared/services/login.service';

@Component({
    selector: 'app-confirm-email',
    templateUrl: './confirm-email.component.html',
    styleUrls: ['./confirm-email.component.scss', '../../login/login.component.scss']
})
export class ConfirmEmailComponent implements OnInit
{

    email: string;

    constructor(
        private Router: Router,
        private activatedRoute: ActivatedRoute,
        private loginService: LoginService
    )
    {
    }

    ngOnInit()
    {
        this.activatedRoute.queryParams.subscribe(params => {
            console.log(params);
            this.email = params.email;
            // this.params = params;
            this.loginService.ValidateEmailConfirmationToken({email: params.email, token: params.code}).subscribe(res => this.emailConfirmed(res), res => this.RequestError(res));
        });
        // this.emailConfirmed();
    }

    emailConfirmed(res)
    {
        console.log(res);
        if (!res) {
            Swal.fire({
                title: 'You are ready to go!',
                html: 'Your email address has now been confirmed!',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Continue'
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.Router.navigateByUrl('login');
                }
            });
        } else {
            if (res.errors[0].code == 'InvalidToken') {
                Swal.fire({
                    title: 'Email Confirmation Token Expired',
                    html: 'Your current email confirmation token has expired. Please click the resend button to recieve a rew token.',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Resend'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.loginService.ReSendEmailConfirmationToken(this.email).subscribe(res => {
                            this.Router.navigateByUrl('login');
                        }, res => this.RequestError(res));
                    }
                });
            }
        }
    }

    RequestError(res: any)
    {
        console.log(res);
        if (res.status == 401) {
            window.location.href = res.error;
        }

        if (res.status == 400 || res.status == 404) {
            this.Router.navigateByUrl('/error');
        }
    }

}
