import {Company}   from './Company';
import {RoleModel} from './role';

export class UserModel
{
    public id: string;
    public userName: string;
    public email: string;
    public fullName: string;
    public companyID: string;
    public roleId: string;
    public role: RoleModel;
    public phoneNumber: string;
    public company: Company;
}
