import {DragDropModule}                                                                                 from '@angular/cdk/drag-drop';
import {ScrollingModule}                                                                                from '@angular/cdk/scrolling';
import {CdkTableModule}                                                                                 from '@angular/cdk/table';
import {CdkTreeModule}                                                                                  from '@angular/cdk/tree';
import {CommonModule}                                                                                   from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule}                                                            from '@angular/common/http';
import {Injectable, LOCALE_ID, NgModule}                                                                from '@angular/core';
import {FormsModule, ReactiveFormsModule}                                                               from '@angular/forms';
import {MatMomentDateModule}                                                                            from '@angular/material-moment-adapter';
import {MatAutocompleteModule}                                                                          from '@angular/material/autocomplete';
import {MatBadgeModule}                                                                                 from '@angular/material/badge';
import {MatBottomSheetModule}                                                                           from '@angular/material/bottom-sheet';
import {MatButtonModule}                                                                                from '@angular/material/button';
import {MatButtonToggleModule}                                                                          from '@angular/material/button-toggle';
import {MatCardModule}                                                                                  from '@angular/material/card';
import {MatCheckboxModule}                                                                              from '@angular/material/checkbox';
import {MatChipsModule}                                                                                 from '@angular/material/chips';
import {MatNativeDateModule, MatRippleModule}                                                           from '@angular/material/core';
import {MatDatepickerModule}                                                                            from '@angular/material/datepicker';
import {MatDialogModule}                                                                                from '@angular/material/dialog';
import {MatDividerModule}                                                                               from '@angular/material/divider';
import {MatExpansionModule}                                                                             from '@angular/material/expansion';
import {MatGridListModule}                                                                              from '@angular/material/grid-list';
import {MatIconModule}                                                                                  from '@angular/material/icon';
import {MatInputModule}                                                                                 from '@angular/material/input';
import {MatListModule}                                                                                  from '@angular/material/list';
import {MatMenuModule}                                                                                  from '@angular/material/menu';
import {MatPaginatorModule}                                                                             from '@angular/material/paginator';
import {MatProgressBarModule}                                                                           from '@angular/material/progress-bar';
import {MatProgressSpinnerModule}                                                                       from '@angular/material/progress-spinner';
import {MatRadioModule}                                                                                 from '@angular/material/radio';
import {MatSelectModule}                                                                                from '@angular/material/select';
import {MatSidenavModule}                                                                               from '@angular/material/sidenav';
import {MatSlideToggleModule}                                                                           from '@angular/material/slide-toggle';
import {MatSliderModule}                                                                                from '@angular/material/slider';
import {MatSnackBarModule}                                                                              from '@angular/material/snack-bar';
import {MatSortModule}                                                                                  from '@angular/material/sort';
import {MatStepperModule}                                                                               from '@angular/material/stepper';
import {MatTableModule}                                                                                 from '@angular/material/table';
import {MatTabsModule}                                                                                  from '@angular/material/tabs';
import {MatToolbarModule}                                                                               from '@angular/material/toolbar';
import {MatTooltipModule}                                                                               from '@angular/material/tooltip';
import {MatTreeModule}                                                                                  from '@angular/material/tree';
import {BrowserModule}                                                                                  from '@angular/platform-browser';
import {BrowserAnimationsModule}                                                                        from '@angular/platform-browser/animations';
import {NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbDateStruct, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule}                                                                                 from '@ng-select/ng-select';
import {PasswordStrengthMeterModule}                                                                    from 'angular-password-strength-meter';
import {TruncateModule}                                                                                 from 'ng2-truncate';
import {NgxCaptchaModule}                                                                               from 'ngx-captcha';
import {CookieService}                                                                                  from 'ngx-cookie-service';
import {IConfig, NgxMaskModule}                                                                         from 'ngx-mask';
import {NgxSpinnerModule}                                                                               from 'ngx-spinner';
import {ToastrModule}                                                                                   from 'ngx-toastr';
import {AppRoutingModule}                                                                               from './app-routing.module';
import {AppComponent}                                                                                   from './app.component';
import {CompanySelectComponent}                                                                         from './core/company-select/company-select.component';
import {ConfirmEmailComponent}                                                                          from './core/confirm-email/confirm-email.component';
import {ForgotPasswordComponent}                                                                        from './core/forgot-password/forgot-password.component';
import {PageNotFoundComponent}                                                                          from './core/page-not-found/page-not-found.component';
import {RegisterComponent}                                                                              from './core/register/register.component';
import {ResetPasswordComponent}                                                                         from './core/reset-password/reset-password.component';
import {ErrorComponent}                                                                                 from './error/error.component';
import {LoginComponent}                                                                                 from './login/login.component';
import {SendLinkComponent}                                                                              from './send-link/send-link.component';
import {TokenInterceptor}                                                                               from './shared/interceptors/token.interceptor';
import {SuccessComponent}                                                                               from './success/success.component';
import {ValidateUserComponent}                                                                          from './validate-user/validate-user.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter
{
    public readonly DELIMITER: '/' = '/';

    public parse(value: string): NgbDateStruct | null
    {
        if (value) {
            const date: string[] = value.split(this.DELIMITER);

            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10),
            };
        }

        return null;
    }

    public format(date: NgbDateStruct | null): string
    {
        return date
            ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
            : '';
    }
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        ConfirmEmailComponent,
        ForgotPasswordComponent,
        PageNotFoundComponent,
        RegisterComponent,
        ResetPasswordComponent,
        ErrorComponent,
        SendLinkComponent,
        ValidateUserComponent,
        SuccessComponent,
        CompanySelectComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxCaptchaModule,
        NgxSpinnerModule,
        ToastrModule.forRoot(),
        NgbDropdownModule,
        MatAutocompleteModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        ScrollingModule,
        TruncateModule,
        MatDatepickerModule,
        MatMomentDateModule,
        CommonModule,
        PasswordStrengthMeterModule,
        NgxMaskModule.forRoot(),
        NgSelectModule
    ],
    providers: [
        CookieService,
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: LOCALE_ID, useValue: 'en-GB'},
        {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
        {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    ],
    bootstrap: [AppComponent],
    exports: [],
})
export class AppModule
{
}
