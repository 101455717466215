import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit
{
    public FirstName: string;
    public Email: string;

    public constructor()
    {
    }

    public ngOnInit(): void
    {
        if (localStorage.getItem('FirstName') != null && localStorage.getItem('Email') != null && localStorage.getItem('FirstName') != '' && localStorage.getItem('Email') != '') {
            this.FirstName = localStorage.getItem('FirstName');
            this.Email = localStorage.getItem('Email');
        }
    }

    public OnClick(): void
    {
        window.open('mail.google.com', '_blank');
    }
}
