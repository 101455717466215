import {Component, OnInit}                                from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog}                                        from '@angular/material/dialog';
import {Title}                                            from '@angular/platform-browser';
import {Router}                                           from '@angular/router';
import {NgxSpinnerService}                                from 'ngx-spinner';
import {ToastrService}                                    from 'ngx-toastr';
import {GlobalService}                                    from '../global.service';
import {LoginService}                                     from '../shared/services/login.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss', './login-background.scss']
})

export class LoginComponent implements OnInit
{
    public passType: string = 'password';
    public submit: boolean = false;
    public loginForm: UntypedFormGroup = new UntypedFormGroup({
        Email: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
        Password: new UntypedFormControl('', [Validators.required, Validators.minLength(5)])
    });

    public constructor(
        public loginService: LoginService,
        public toastr: ToastrService,
        public router: Router,
        public dialog: MatDialog,
        private titleService: Title,
        private spinnerService: NgxSpinnerService,
        private globalService: GlobalService,
    )
    {
    }

    public ngOnInit(): void
    {
        this.titleService.setTitle('MTA Plus - ' + 'Login');
    }

    public OnLoginClick(): void
    {
        this.spinnerService.show();
        this.submit = true;

        if (this.loginForm.controls.Password.value.trim() == '' || this.loginForm.controls.Email.value.trim() == '') {
            this.toastr.error('Please enter valid credentials.', 'Info', {tapToDismiss: true});
            return;
        } else {
            this.loginService.ValidateClientDetails(this.loginForm.controls.Email.value.trim(), this.loginForm.controls.Password.value.trim()).subscribe(
                res => this.ValidateRequestSuccess(res),
                res => this.ValidateRequestError(res)
            );
        }
    }

    public ValidateRequestSuccess(info)
    {
        if (info.accociatedUsers) {
            if (info.accociatedUsers.length == 1) {
                localStorage.setItem('title', 'Dashboard');
                this.router.navigate(['/layout/dashboard']);
            } else {
                this.router.navigateByUrl('/company-select?data=' + btoa(this.loginForm.controls.Password.value.trim()));
                this.globalService.setMultiUser(info.accociatedUsers);
            }
        } else {
            localStorage.setItem('title', 'Dashboard');
            this.router.navigate(['/layout/dashboard']);
        }

        this.globalService.setUsersName(info.fullName);
        this.globalService.setUsersID(info.id);
        this.globalService.setUsersToken(info.token);
        this.globalService.setCompanyName(info.company_Name);
        this.globalService.setCompanyID(info.companyID);
        this.globalService.setVehicleColumns(info.company.vehicleColumns);

        info.company.vehicleColumns = [];

        this.globalService.setCompanyDetails(info.company);
        this.globalService.setRole(info.role);
        this.globalService.setNotifications(info.notifications);
    }

    public ValidateRequestError(res)
    {
        if (res.status == 0) {
            this.toastr.error('Unable to login, please add-contact MTA Support.', 'Error', {tapToDismiss: true});
        } else {
            this.toastr.error(res.error.message, 'Error ' + res.status, {tapToDismiss: true});
        }

        this.spinnerService.hide();
    }

    public GetAccesstokenFromAPIsuccess(res)
    {
        localStorage.setItem('title', this.loginForm.controls.Username.value);
        this.spinnerService.hide();
        this.router.navigateByUrl('/layout/settings');
    }
}
