import {NgModule}                from '@angular/core';
import {RouterModule, Routes}    from '@angular/router';
import {CompanySelectComponent}  from './core/company-select/company-select.component';
import {ConfirmEmailComponent}   from './core/confirm-email/confirm-email.component';
import {ForgotPasswordComponent} from './core/forgot-password/forgot-password.component';
import {PageNotFoundComponent}   from './core/page-not-found/page-not-found.component';
import {RegisterComponent}       from './core/register/register.component';
import {ResetPasswordComponent}  from './core/reset-password/reset-password.component';
import {ErrorComponent}          from './error/error.component';
import {LoginComponent}          from './login/login.component';
import {SendLinkComponent}       from './send-link/send-link.component';
import {SuccessComponent}        from './success/success.component';
import {ValidateUserComponent}   from './validate-user/validate-user.component';

const routes: Routes = [

    {path: '', redirectTo: '/login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'register', component: RegisterComponent},
    {path: 'confirm-email', component: ConfirmEmailComponent},
    {path: 'forgot-password', component: ForgotPasswordComponent},
    {path: 'reset-password', component: ResetPasswordComponent},
    {path: 'page-not-found', component: PageNotFoundComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'sendLink', component: SendLinkComponent},
    {path: 'validate-user', component: ValidateUserComponent},
    {path: 'success', component: SuccessComponent},
    {path: 'company-select', component: CompanySelectComponent},
    // { path: 'setting', component: SendLinkComponent },
    {
        path: 'layout',
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)
    },
    {path: '**', redirectTo: '/login', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64]
    })],
    exports: [RouterModule]
})
export class AppRoutingModule
{
}
