import {Component, OnInit}                                                                                from '@angular/core';
import {FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ErrorStateMatcher}                                                                                from '@angular/material/core';
import {Title}                                                                                            from '@angular/platform-browser';
import {ActivatedRoute, Router}                                                                           from '@angular/router';
import jwt_decode                                                                                         from 'jwt-decode';
import {NgxSpinnerService}                                                                                from 'ngx-spinner';
import {ToastrService}                                                                                    from 'ngx-toastr';
import {environment}                                                                                      from '../../../environments/environment';
import {Company}                                                                                          from '../../shared/models/Company';
import {RegisterModel}                                                                                    from '../../shared/models/Register';
import {AddressService}                                                                                   from '../../shared/services/address.service';
import {CompanyService}                                                                                   from '../../shared/services/company.service';
import {LoginService}                                                                                     from '../../shared/services/login.service';
import {MTACloudXeroService}                                                                              from '../../shared/services/mtacloud-xero.service';


export class MyErrorStateMatcher implements ErrorStateMatcher
{
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean
    {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
        return (invalidCtrl || invalidParent);
    }
}

export interface Food
{
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss', '../../login/login-background.scss']
})

export class RegisterComponent implements OnInit
{
    public RoleArry: Array<any> = [];
    public roleId: string;
    siteKey = '6LfEpKsUAAAAAMUeJ6rCBfEWXq-sp6cfM-A71fdp';
    matcher = new MyErrorStateMatcher();
    _registermodel: RegisterModel;
    // passwordFormGroup: FormGroup;
    userForm: boolean = false;
    // RegisterForm: FormGroup;
    selectedCompany: Company;
    password: string = '';
    FullRegister: boolean;
    submitted: boolean;
    postalAddresses = [];
    registeredAddresses = [];
    postalPcValid: boolean;
    registeredPcValid: boolean;
    postalSearch: boolean;
    registerdSearch: boolean;
    multiCompany: boolean = false;
    UserCompanyDetails: UntypedFormGroup = new UntypedFormGroup({
        companyMappingId: new UntypedFormControl('', [Validators.required]),
        Email: new UntypedFormControl('', Validators.compose([
            Validators.required,
            Validators.email
        ])),
    });
    companyForm: UntypedFormGroup = new UntypedFormGroup({
        'Company_Name': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'First_Name': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'Last_Name': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'Email': new UntypedFormControl('', Validators.compose([
            Validators.required,
            Validators.email
        ])),
        'Phone': new UntypedFormControl('', Validators.compose([])),
        'Mobile': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'Skype': new UntypedFormControl('', Validators.compose([])),
        'Website': new UntypedFormControl('', Validators.compose([])),
        'Postal_Postcode': new UntypedFormControl('', Validators.compose([
            Validators.required,
            Validators.pattern('^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$')
        ])),
        'Selected_Address': new UntypedFormControl('', Validators.compose([])),
        'Address_Line_1': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'Address_Line_2': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'City_Town': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'Region': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'Postcode': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'Country': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'Registered_Postcode_Input': new UntypedFormControl('', Validators.compose([
            Validators.required,
            Validators.pattern('^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$')
        ])),
        'Registered_Selected_Address': new UntypedFormControl('', Validators.compose([])),
        'Registered_Address_Line_1': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'Registered_Address_Line_2': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'Registered_City_Town': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'Registered_Region': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'Registered_Postcode': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
        'Registered_Country': new UntypedFormControl('', Validators.compose([
            Validators.required
        ])),
    });
    UserDetails: UntypedFormGroup = new UntypedFormGroup({
        contactFirstName: new UntypedFormControl('', [Validators.required]),
        contactLastName: new UntypedFormControl('', [Validators.required]),
        emailAddress: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
        phoneNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(/^-?(0|[0-9]\d*)?$/)]),
        // password: new FormControl('', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]),
        password: new UntypedFormControl('', [Validators.required, Validators.pattern('(?=^.{6,255}$)((?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))^.*')]),
        confirmPassword: new UntypedFormControl('', [Validators.required]),
        // check:new FormControl('', [Validators.required]),
        // recaptcha: new FormControl('', Validators.required),
    });
    ConfirmDetails: UntypedFormGroup = new UntypedFormGroup({
        check: new UntypedFormControl('', [Validators.requiredTrue]),
        recaptcha: new UntypedFormControl('', Validators.required),
    });
    AccountPairingForm: UntypedFormGroup = new UntypedFormGroup({
        Password: new UntypedFormControl('', Validators.required),
    });
    protected aFormGroup: UntypedFormGroup;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private loginService: LoginService,
        public toastr: ToastrService,
        private router: Router,
        private _titleService: Title,
        private spinnerService: NgxSpinnerService,
        private companyService: CompanyService,
        private addressService: AddressService,
        private route: ActivatedRoute,
        private xeroService: MTACloudXeroService
    )
    {
        this.spinnerService.show();
        this._registermodel = new RegisterModel();

        this.route.queryParams.subscribe(params => {
            console.log('Xero Params', params);
            // this.params = params;
            if (params.code) {

                var fromObject = {
                    grantType: 'authorization_code',
                    code: params.code,
                    redirect_uri: `${environment.xero.returnUrl}/register`,
                };

                this.xeroService.RegisterUsingXero(fromObject).subscribe(res => {
                        this.cForm.Company_Name.setValue(res.organisation.tenantName);

                        let decodedData: any = jwt_decode(res.user.id_token);
                        this.cForm.First_Name.setValue(decodedData.given_name);
                        this.cForm.Last_Name.setValue(decodedData.family_name);
                        this.cForm.Email.setValue(decodedData.email);

                        this.FullRegister = true;
                        this.userForm = true;
                        this.spinnerService.hide();
                    },
                    res => {
                        this.RequestError(res);
                        this.router.navigate([], {
                            relativeTo: this.route,
                            queryParams: [],
                            queryParamsHandling: ''
                        });
                        this.spinnerService.hide();
                    }
                );
            } else {
                this.spinnerService.hide();
            }
        });
    }

    public get cForm()
    {
        return this.companyForm.controls;
    }

    get f()
    {
        return this.companyForm.controls;
    }

    passwordChange()
    {
        this.password = this.UserDetails.get('password').value;
    }

    ngOnInit()
    {
        //debugger;
        //this.loginService.GetRoleList().subscribe(res => this.ValidateRequestSuccess(res), res => this.ValidateRequestError(res));
        this._titleService.setTitle('MTA Plus - ' + 'Register');
        this.aFormGroup = this.formBuilder.group({
            recaptcha: ['', Validators.required]
        });

        this.postalPcValid = false;
        this.registeredPcValid = false;

        this.route.queryParams.subscribe(params => {
            // console.log(params['email']);
            if (params['email'] && params['code']) {
                this.userForm = true;
                this.FullRegister = false;
                this.UserCompanyDetails.controls['Email'].setValue(params['email']);

                this.UserCompanyDetails.controls['companyMappingId'].setValue(params['code']);

                this.findCompany();
                this.UserCompanyDetails.controls['companyMappingId'].disable();
                this.UserCompanyDetails.controls['Email'].disable();
            }
        });

    }

    connectToXero()
    {
        window.location.href = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=A1FC34E3493A4B47AA10784401518D98&redirect_uri=${environment.xero.returnUrl}/register&scope=openid profile email`;
    }

    findCompany()
    {
        if (this.UserCompanyDetails.get('companyMappingId').valid) {
            this.companyService.GetCompanyUsingMappingId(this.UserCompanyDetails.get('companyMappingId').value, this.UserCompanyDetails.get('Email').value).subscribe(res => this.MapCompanyInfo(res),
                res => this.FindCompanyError(res));
        }
    }

    FindCompanyError(info)
    {
        console.log(info.status);
        this.spinnerService.hide();
        this.toastr.error('The details you have entered do not match any in our database. Please check the mapping id is correct and hasnt expired.', 'Failed Search', {tapToDismiss: true});
    }

    MapCompanyInfo(res)
    {
        console.log(res);
        if (res.company.company_Name == '') {
            this.FindCompanyError(res);
        } else {
            this.selectedCompany = res.company;
        }

        this.multiCompany = res.multiCompany;
    }

    transferEmail()
    {
        this.UserDetails.controls['emailAddress'].setValue(this.UserCompanyDetails.get('Email').value);
        this.UserDetails.controls['emailAddress'].disable();
    }

    ValidateRequestSuccess(info)
    {
        //debugger;
        if (info.length > 0) {
            this.RoleArry = info;
        }
    }

    ValidateRequestError(res)
    {
        //debugger;
        this.toastr.info('Data not found for Role list.', 'Info', {tapToDismiss: true});
    }

    LinkCompanyWithAccount()
    {
        console.log(this.UserCompanyDetails);
        console.log(this.AccountPairingForm);
        if (this.AccountPairingForm.valid) {
            this.UserCompanyDetails.controls['Email'].enable();
            this.UserCompanyDetails.controls['companyMappingId'].enable();

            var userDetails = {
                emailAddress: this.UserCompanyDetails.controls['Email'].value,
                password: this.AccountPairingForm.get('Password').value
            };

            var data = {
                Company: this.UserCompanyDetails.value,
                User: userDetails
            };

            this.companyService.MapUserToCompany(data).subscribe(res => this.CompanySuccess(res, false),
                res => this.RequestError(res));
        }
    }

    OnRegisterClick()
    {
        this.spinnerService.show();

        this._registermodel.CompanyName = this.UserDetails.controls.companyName.value.trim();
        this._registermodel.FirstName = this.UserDetails.controls.contactFirstName.value.trim();
        this._registermodel.LastName = this.UserDetails.controls.contactLastName.value.trim();
        this._registermodel.EmailId = this.UserDetails.controls.emailAddress.value.trim();
        this._registermodel.ContactNumber = this.UserDetails.controls.phoneNumber.value;

        this.loginService.SignUpClient(this._registermodel).subscribe(res => this.RequestSuccess(res), res => this.RequestError(res));

    }

    RequestSuccess(info)
    {
        //debugger;
        console.log(info);
        this.spinnerService.hide();
        if (info.Result == '1') {
            //this.toastr.info(info.message, 'Info', { tapToDismiss: true });
            localStorage.setItem('FirstName', info.FirstName);
            localStorage.setItem('Email', info.Email);
            this.router.navigateByUrl('success');

        } else if (info.Result == '-1' || info.Result == '0') {
            this.toastr.info(info.message, 'Info', {tapToDismiss: true});
        } else if (info.Result == '') {
            this.toastr.info(info.message, 'Info', {tapToDismiss: true});
        }

    }

    GetPostalAddress()
    {
        this.spinnerService.show();
        this.postalSearch = true;
        if (this.companyForm.controls.Postal_Postcode.valid) {
            console.log(this.companyForm.controls.Postal_Postcode.value);
            this.addressService.GetAddresses(this.companyForm.controls.Postal_Postcode.value).subscribe(res => this.PostalRequestSuccess(res),
                res => this.RequestError(res));
        } else {
            this.postalPcValid = false;
            this.spinnerService.hide();
        }
    }

    PostalRequestSuccess(res: any)
    {
        this.postalAddresses = res.addresses;
        this.postalPcValid = true;
        this.spinnerService.hide();

    }

    onChange(index)
    {
        this.postalSearch = true;
        this.companyForm.controls['City_Town'].setValue(this.postalAddresses[index].town_or_city);
        this.companyForm.controls['Region'].setValue(this.postalAddresses[index].county);
        this.companyForm.controls['Address_Line_1'].setValue(this.postalAddresses[index].line_1);
        if (this.postalAddresses[index].line_2) {
            this.companyForm.controls['Address_Line_2'].setValue(this.postalAddresses[index].line_2);
        } else {
            this.companyForm.controls['Address_Line_2'].setValue(this.postalAddresses[index].locality);
        }
        this.companyForm.controls['Country'].setValue(this.postalAddresses[index].country);
        this.companyForm.controls['Postcode'].setValue(this.companyForm.controls.Postal_Postcode.value);
    }

    GetRegisteredAddress()
    {
        this.spinnerService.show();
        this.registerdSearch = true;
        if (this.companyForm.controls.Registered_Postcode_Input.valid) {
            this.addressService.GetAddresses(this.companyForm.controls.Registered_Postcode_Input.value).subscribe(res => this.RegisteredRequestSuccess(res),
                res => this.RequestError(res));
        } else {
            this.registeredPcValid = false;
            this.spinnerService.hide();
        }
    }

    RegisteredRequestSuccess(res: any)
    {
        this.registeredAddresses = res.addresses;
        this.registeredPcValid = true;
        this.spinnerService.hide();
    }

    onChangeRegistered(index)
    {
        this.registerdSearch = true;
        this.companyForm.controls['Registered_City_Town'].setValue(this.registeredAddresses[index].town_or_city);
        this.companyForm.controls['Registered_Region'].setValue(this.registeredAddresses[index].county);
        this.companyForm.controls['Registered_Address_Line_1'].setValue(this.registeredAddresses[index].line_1);
        if (this.registeredAddresses[index].line_2) {
            this.companyForm.controls['Registered_Address_Line_2'].setValue(this.registeredAddresses[index].line_2);
        } else {
            this.companyForm.controls['Registered_Address_Line_2'].setValue(this.registeredAddresses[index].locality);
        }
        this.companyForm.controls['Registered_Country'].setValue(this.registeredAddresses[index].country);
        this.companyForm.controls['Registered_Postcode'].setValue(this.companyForm.controls.Registered_Postcode_Input.value);
    }

    AddressReplicate(event)
    {
        this.registerdSearch = true;
        if (event.checked) {
            this.setRegisteredAddress();
            this.registeredPcValid = false;
        } else {
            this.ClearRegisteredAddress();
            this.registeredPcValid = false;
        }
    }

    ClearRegisteredAddress()
    {
        this.companyForm.controls['Registered_City_Town'].setValue('');
        this.companyForm.controls['Registered_Region'].setValue('');
        this.companyForm.controls['Registered_Address_Line_1'].setValue('');
        this.companyForm.controls['Registered_Address_Line_2'].setValue('');
        this.companyForm.controls['Registered_Country'].setValue('');
        this.companyForm.controls['Registered_Postcode'].setValue('');
        this.companyForm.controls['Registered_Postcode_Input'].setValue('');
    }

    setRegisteredAddress()
    {
        this.companyForm.controls['Registered_Address_Line_1'].setValue(this.companyForm.controls.Address_Line_1.value);
        this.companyForm.controls['Registered_Address_Line_2'].setValue(this.companyForm.controls.Address_Line_2.value);
        this.companyForm.controls['Registered_City_Town'].setValue(this.companyForm.controls.City_Town.value);
        this.companyForm.controls['Registered_Region'].setValue(this.companyForm.controls.Region.value);
        this.companyForm.controls['Registered_Country'].setValue(this.companyForm.controls.Country.value);
        this.companyForm.controls['Registered_Postcode'].setValue(this.companyForm.controls.Postcode.value);
        this.companyForm.controls['Registered_Postcode_Input'].setValue(this.companyForm.controls.Postcode.value);
    }


    RequestError(info)
    {
        var error = info.error ? info.error : info.message;
        console.log(info);
        //debugger;
        this.spinnerService.hide();
        this.toastr.error(error, 'Error', {tapToDismiss: true});
    }


    CancelClick()
    {
        this.router.navigateByUrl('login');
    }

    SubmitFullSignUp()
    {
        if (this.ConfirmDetails.valid) {
            console.log(this.companyForm.value);
            console.log(this.UserDetails.value);

            var data = {
                Company: this.companyForm.value,
                User: this.UserDetails.value
            };

            this.companyService.CreateNewCompany(data).subscribe(res => this.CompanySuccess(res, true),
                res => this.RequestError(res));
        } else {
            this.toastr.error('Please check you have agreed to the terms of use & the MTA privacy policy.', 'Invalid Form');
        }
    }

    submitUserApplication()
    {
        if (this.ConfirmDetails.valid) {
            this.UserDetails.controls['emailAddress'].enable();

            console.log(this.UserCompanyDetails.value);
            console.log(this.UserDetails.value);

            var data = {
                Company: this.UserCompanyDetails.value,
                User: this.UserDetails.value
            };

            this.companyService.CreateNewUser(data).subscribe(res => this.CompanySuccess(res, false),
                res => this.RequestError(res));
        } else {
            this.toastr.error('Please check you have agreed to the terms of use & the MTA privacy policy.', 'Invalid Form');
        }
    }

    CompanySuccess(res, freeTrail)
    {
        console.log(res);
        console.log(res);
        if (freeTrail) {
            this.toastr.success('You have successfully signed up to MTA Plus, your 30 day free trail has started.', 'Successfull Signup');
        } else {
            this.toastr.success('You have successfully signed up to MTA Plus.', 'Successfull Signup');
        }
        this.router.navigateByUrl('login');
    }

    changeRole(e)
    {
        this.roleId = e.target.selectedIndex;
    }
}
