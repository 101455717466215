import {HttpClient}    from '@angular/common/http';
import {Injectable}    from '@angular/core';
import {Router}        from '@angular/router';
import {Observable}    from 'rxjs';
import {GlobalService} from '../../global.service';
import {Company}       from '../models/Company';
import {DealerPayment} from '../models/DealerPayment';
import {BaseService}   from './base.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyService extends BaseService
{
    public constructor(
        private http: HttpClient,
        public router: Router,
        private globalService: GlobalService
    )
    {
        super(router);
    }

    public AddCompanyDetail(data: Company): Observable<any>
    {
        return this.http.post(`${this.apiUrl}/Company`, data).pipe();
    }

    public GetCompanies(): Observable<any>
    {
        return this.http.get(`${this.apiUrl}/Company`).pipe();
    }

    public GetLatestCompanies(): Observable<any>
    {
        return this.http.get(`${this.apiUrl}/Company/Latest`).pipe();
    }

    public EditCompany(id, company): Observable<any>
    {
        return this.http.put(`${this.apiUrl}/Company/${id}`, company).pipe();
    }

    public GetCompany(id): Observable<any>
    {
        return this.http.get(`${this.apiUrl}/Company/${id}`).pipe();
    }

    public GetCompanyUsers(): Observable<any>
    {
        let companyId = this.globalService.getCompanyID();
        return this.http.get(`${this.apiUrl}/Company/GetCompanyUsers/${companyId}`).pipe();
    }

    public UpdateUserDetails(id: string, user): Observable<any>
    {
        return this.http.put(`${this.apiUrl}/ApplicationUser/${id}`, user);
    }

    public GetCompanyUsingMappingId(code, email): Observable<any>
    {
        return this.http.post(`${this.apiUrl}/Company/withmappingid`, {
            UsersEmail: email,
            Code: code
        }).pipe();
    }

    public GetAdminCompany(id): Observable<any>
    {
        return this.http.get(`${this.apiUrl}/Company/AdminSettings/${id}`).pipe();
    }

    public GetUnits(): Observable<any>
    {
        return this.http.get(`${this.apiUrl}/Units`).pipe();
    }

    public UpdateXeroCredentials(id, data): Observable<any>
    {
        return this.http.post(`${this.apiUrl}/Company/Xero/${id}`, data).pipe();
    }

    public GetDashboard(): Observable<any>
    {
        let id = this.globalService.getCompanyID();
        return this.http.get(`${this.apiUrl}/dashboard/company/${id}`).pipe();
    }

    public GetDashboardChart(type): Observable<any>
    {
        let id = this.globalService.getCompanyID();
        return this.http.get(`${this.apiUrl}/dashboard/barchart/${id}/${type}`).pipe();
    }

    public CreateNewCompany(data)
    {
        return this.http.post(`${this.apiUrl}/Company/RegisterCompany`, data).pipe();
    }

    public CreateNewUser(data)
    {
        return this.http.post(`${this.apiUrl}/Company/RegisterUser`, data).pipe();
    }

    public MapUserToCompany(data)
    {
        return this.http.post(`${this.apiUrl}/Company/CreateCompanyLink`, data).pipe();
    }

    public GetUser(): Observable<any>
    {
        let usersId = this.globalService.getUsersID();
        return this.http.get(`${this.apiUrl}/ApplicationUser/GetUser/${usersId}`).pipe();
    }

    public InviteUser(name, email)
    {
        return this.http.post(`${this.apiUrl}/Company/InviteUser/${name}`, {
            CompanyId: this.globalService.getCompanyID(),
            UsersEmail: email
        }).pipe();
    }


    // Roles & Permissions
    public GetCompanyRoles(): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        return this.http.get(`${this.apiUrl}/Company/GetCompanyRoles/${companyId}`).pipe();
    }

    public checkServerPermission()
    {
        return this.http.get(`${this.apiUrl}/Company/TestPoint`).pipe();
    }


    // Dealer Payments
    public AddPaymentDetails(data): Observable<any>
    {
        return this.http.post(`${this.apiUrl}/Company/PaymentDetails`, data).pipe();
    }

    public EditPaymentDetails(data: DealerPayment): Observable<any>
    {
        return this.http.put(`${this.apiUrl}/Company/PaymentDetails/${data.dealerPaymentId}`, data).pipe();
    }

    public GetAllRequestedDDPayments()
    {
        return this.http.get(`${this.apiUrl}/Company/RequestedPayments/DD`).pipe();
    }

    public GetAllRequestedAnnualPayments()
    {
        return this.http.get(`${this.apiUrl}/Company/RequestedPayments/Annual`).pipe();
    }

    // Lookup Requests
    public AddLookupRequest(data): Observable<any>
    {
        return this.http.post(`${this.apiUrl}/Lookup/LookupRequest`, data).pipe();
    }

    public ApproveLookupRequests(data): Observable<any>
    {
        return this.http.put(`${this.apiUrl}/Lookup/ApproveLookupRequest/${data.lookupRequestId}`, data).pipe();
    }

    public DeleteLookupRequests(id): Observable<any>
    {
        return this.http.delete(`${this.apiUrl}/Lookup/DeleteLookupRequest/${id}`).pipe();
    }

    public GetAllLookupRequests(): Observable<any>
    {
        return this.http.get(`${this.apiUrl}/Company/RequestedLookups`).pipe();
    }

    public GetCompanyLookupRequests(id): Observable<any>
    {
        return this.http.get(`${this.apiUrl}/Company/PreviousRequestedLookups/${id}`).pipe();
    }

    public GetLookupPackages(): Observable<any>
    {
        return this.http.get(`${this.apiUrl}/Company/GetLookupPackages`).pipe();
    }
}
