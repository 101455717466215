import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable}                          from '@angular/core';
import {Router}                              from '@angular/router';
import {Observable}                          from 'rxjs';
import {GlobalService}                       from '../../global.service';
import {ContactModel}                        from '../models/Contact';
import {XeroInvoicesModel}                   from '../models/Invoices';
import {BaseService}                         from './base.service';

@Injectable({
    providedIn: 'root'
})
export class MTACloudXeroService extends BaseService
{
    public constructor(
        public router: Router,
        private http: HttpClient,
        private globalService: GlobalService
    )
    {
        super(router);
    }

// ------------------------add-contact--------------------------------

    public CreateNewContact(data): Observable<any>
    {
        const id = this.globalService.getCompanyID();
        return this.http.post(this.apiUrl + '/XeroContacts/AddContact/' + id, data).pipe();
    }

    public UpdateContact(data: ContactModel): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        return this.http.post(this.apiUrl + '/XeroContacts/UpdateContact/' + companyId, data).pipe();
    }

    public GetContactList(rawQueryParams?): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        let params = new HttpParams();

        // if(pageNumber != null){
        //   params = params.set('pageNumber', pageNumber);
        // }

        if (rawQueryParams) {
            rawQueryParams.forEach(param => {
                params = params.set(param.name, param.value);
            });
        }

        const httpOptions = {
            params
        };

        return this.http.get(this.apiUrl + '/XeroContacts/GetContacts/' + companyId, httpOptions).pipe();
    }

    public GetContactGroupList(): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        return this.http.get(this.apiUrl + '/XeroContacts/GetContactGroups/' + companyId).pipe();
    }

    public GetContactGroup(id: string): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        return this.http.get(this.apiUrl + '/XeroContacts/GetContactGroup/' + companyId + '/' + id).pipe();
    }

    public CreateContactGroup(name: string): Observable<any>
    {
        return this.http.post(this.apiUrl + '/XeroContacts/CreateContactGroup', {name}).pipe();
    }

    public GetContactByID(contactID): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        return this.http.get(this.apiUrl + '/XeroContacts/GetContactOnly/' + companyId + '/' + contactID).pipe();
    }

    public GetContactDetailByID(contactID: any): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        return this.http.get(this.apiUrl + '/XeroContacts/GetContact/' + companyId + '/' + contactID).pipe();
    }

    public GetInvoiceContacts(contactID: any, financeCompanyID: any): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        return this.http.post(this.apiUrl + '/XeroContacts/GetInvoiceContacts/' + companyId + '/' + contactID, {financeCompanyId: financeCompanyID}).pipe();
    }

    public GetPurchaseInvoiceContacts(contactID: any, CompanyId): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        return this.http.post(this.apiUrl + '/XeroContacts/GetPurchaseInvoiceContacts/' + companyId + '/' + contactID, {CompanyId}).pipe();
    }

    public GetFinanceCompanies(): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        return this.http.get(this.apiUrl + '/xero/GetFinanceCompanies/' + companyId).pipe();
    }

    public GetContactListByName(search): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();

        const url = this.apiUrl + '/XeroContacts/SearchContacts/' + companyId;

        if (search) {
            const url = this.apiUrl + '/XeroContacts/SearchContacts/' + companyId + '/' + search;
        }

        return this.http.get(url).pipe();
    }

    public findAddress(postcode: String): Observable<any>
    {
        return this.http.get(this.apiUrl + '/MTACloudXero/findAddress/' + postcode).pipe();
    }

    // -----------------------------Invoice-----------------------
    // GetInvoiceList(): Observable<any> {
    //   return this.http.get(this.apiUrl + "/XeroInvoice").pipe();
    // }

    public GetInvoiceList(pageSize = 12, pageNumber = 1, rawQueryParams?): Observable<any>
    {
        let params = new HttpParams();

        if (pageSize != null) {
            params = params.set('pageSize', pageSize);
        }

        if (pageNumber != null) {
            params = params.set('pageNumber', pageNumber);
        }

        if (rawQueryParams) {
            rawQueryParams.forEach(param => {
                params = params.set(param.name, param.value);
            });
        }

        const httpOptions = {
            params
        };

        return this.http.get(this.apiUrl + '/XeroInvoice', httpOptions).pipe();
    }

    public GetInvoiceListForVehicle(vehicleId): Observable<any>
    {
        return this.http.get(this.apiUrl + '/XeroInvoice/InvoicesForVehicle/' + vehicleId).pipe();
    }

    public GetInvoiceDetailByID(InvoiceID: any): Observable<any>
    {
        return this.http.get(this.apiUrl + '/XeroInvoice/' + InvoiceID).pipe();
    }

    // CreateNewInvoice(data:InvoicesModel): Observable<any> {
    //   return this.http.post(this.apiUrl + "/MTACloudXero/CreateInvoice", data).pipe();
    // }

    public UpdateInvoice(data: XeroInvoicesModel, invoiceId, vehicleId = null): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        let vehicleIdString = '';

        if (vehicleId !== null) {
            vehicleIdString = '/' + vehicleId;
        }

        return this.http.post(this.apiUrl + '/xero/UpdateInvoice/' + companyId + '/' + invoiceId + vehicleIdString, data).pipe();
    }

    public CreateInvoice(data: XeroInvoicesModel, vehicleId = null): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        let vehicleIdString = '';

        if (vehicleId !== null) {
            vehicleIdString = '/' + vehicleId;
        }

        return this.http.post(this.apiUrl + '/xero/CreateInvoice/' + companyId + vehicleIdString, data).pipe();
    }

    public GetAccountList(): Observable<any>
    {
        const id = this.globalService.getCompanyID();
        return this.http.get(this.apiUrl + '/XeroInvoice/GetAccounts/' + id).pipe();
    }

    // ---------------------------------Item-----------------------------------
    public GetItemsList(): Observable<any>
    {

        return this.http.get(this.apiUrl + '/MTACloudXero/GetItemsList').pipe();
    }

    public GetItemsCode(): Observable<any>
    {

        return this.http.get(this.apiUrl + '/MTACloudXero/GetItemsCode').pipe();
    }

    public GetItemsDetailsBYCode(Code: String): Observable<any>
    {

        return this.http.get(this.apiUrl + '/MTACloudXero/GetItemsDetailsBYCode/' + Code + '/' + 'fsdfs').pipe();


    }

    // -----------------------------tax Rate--------------------------

    public GetTaxRate(TaxType: string): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        return this.http.get(this.apiUrl + '/xero/GetTaxRate/' + companyId + '/' + TaxType).pipe();
    }

    public GetTaxRates(): Observable<any>
    {
        const companyId = this.globalService.getCompanyID();
        return this.http.get(this.apiUrl + '/xero/GetTaxRates/' + companyId).pipe();
    }

    // ----------------------------Xero Connect-------------------------
    public GetXeroToken(data, clientId, secretKey): Observable<any>
    {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Authorization', 'Basic ' + btoa(clientId + ':' + secretKey));
        headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.append('skip', 'true');

        return this.http.post('https://identity.xero.com/connect/token', data, {headers}).pipe();
    }

    public XeroInitialConnect(params): Observable<any>
    {
        const id = this.globalService.getCompanyID();
        return this.http.post(this.apiUrl + '/XeroConnect/InitialXeroConnection/' + id, params).pipe();
    }

    public RegisterUsingXero(params): Observable<any>
    {
        return this.http.post(this.apiUrl + '/XeroConnect/GetOrganisation', params).pipe();
    }

    public GetXeroClientId(): Observable<any>
    {
        return this.http.get(this.apiUrl + '/SystemKey/XeroClientid', {responseType: 'text'}).pipe();
    }

    public GetXeroSecretKey(): Observable<any>
    {
        return this.http.get(this.apiUrl + '/SystemKey/XeroSecretKey', {responseType: 'text'}).pipe();
    }

    // Vehicles Contacts
    public GeVehicleContacts(id, search): Observable<any>
    {
        return this.http.get(this.apiUrl + '/XeroContacts/GetContacts/' + id + '/Vehicle/' + search).pipe();
    }


}
