import {Component, OnInit}                                from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Title}                                            from '@angular/platform-browser';
import {Router}                                           from '@angular/router';
import {NgxSpinnerService}                                from 'ngx-spinner';
import {ToastrService}                                    from 'ngx-toastr';
import {GlobalService}                                    from '../global.service';
import {LoginService}                                     from '../shared/services/login.service';

@Component({
    selector: 'app-validate-user',
    templateUrl: './validate-user.component.html',
    styleUrls: ['./validate-user.component.scss']
})
export class ValidateUserComponent implements OnInit
{
    public setPasswordForm: UntypedFormGroup;
    public token: string;

    public constructor(
        private formBuilder: UntypedFormBuilder,
        private titleService: Title,
        private loginService: LoginService,
        private router: Router,
        private globalService: GlobalService,
        private spinnerService: NgxSpinnerService,
        public toastr: ToastrService,
    )
    {
        this.setPasswordForm = this.formBuilder.group({
            Password: ['', [Validators.required, Validators.minLength(8)]],
        });
    }

    public ngOnInit(): void
    {
        this.titleService.setTitle('MTA Plus - ' + 'Activate Account');
        const url = window.location.href;
        if (url.indexOf('token=') != -1) {
            const i = url.indexOf('token=') + 6;
            this.token = url.substr(i, 36);

            this.validatetoken(this.token);
        }
    }

    public validatetoken(token: string)
    {
        this.loginService.ValidateEmailConfirmationToken(token)
            .subscribe((res) => this.RequestSuccess(res), (res) => this.RequestError(res));

    }

    public RequestSuccess(res)
    {

        if (res.Result == '1') {

            this.globalService.setToken(res.Token);

        } else if (res.Result == '2') {
            this.globalService.setToken(res.Token);
            this.router.navigateByUrl('login');
        } else if (res.Result == false) {

            this.router.navigateByUrl('/error');


        }

    }

    public RequestError(res)
    {

    }

    public setPassClick()
    {

        const password = this.setPasswordForm.controls.Password.value;

        if (this.setPasswordForm.invalid) {
            this.setPasswordForm.get('Password').markAsTouched();
            return;
        } else {
            this.token = this.globalService.getToken();
            this.loginService.setPassword(password, this.token).subscribe((res) => this.passwordSetSuccess(res), (res) => this.PasswordRequestError(res));
            this.spinnerService.show();
        }


    }

    public passwordSetSuccess(res)
    {

        if (res.Result == true) {

            this.toastr.success(res.message, 'Success!', {tapToDismiss: true});

            this.spinnerService.hide();
            this.router.navigateByUrl('login');
        } else if (res.Result == false) {

            this.router.navigateByUrl('/error');
            this.spinnerService.hide();
        }
    }

    public PasswordRequestError(res)
    {

        this.spinnerService.hide();
    }

}
