import {Location}  from '@angular/common';
import {Component} from '@angular/core';
import {Router}    from '@angular/router';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss', '../login/login-background.scss']
})
export class ErrorComponent
{
    public constructor(
        private router: Router,
        public location: Location)
    {
    }

    public OnCancelClick(): void
    {
        this.router.navigateByUrl('layout/dashboard');
    }
}
