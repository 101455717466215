<div class="wrapper">
    <div class="authentication-header area">
        <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>

    <div class="authentication-forgot d-flex align-items-center justify-content-center my-5 my-lg-0">
        <div class="container-fluid">
            <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                <div class="col mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <div class="p-4 rounded">
                                <div class="text-center">
                                    <span class="fa-regular fa-unlock" style="color: #229ca9; font-size: 7em;"></span>
                                </div>

                                <h4 class="mt-5 font-weight-bold">Forgot Password?</h4>
                                <p class="text-muted">Enter your registered email address to reset your password</p>

                                <form [formGroup]="forgotForm">
                                    <div class="my-4">
                                        <label class="form-label">Email Address</label>
                                        <input (ngModelChange)="SetGlobalData()" [(ngModel)]="id" class="form-control" formControlName="email" placeholder="example@user.com" type="email" />
                                        <div *ngIf="forgotForm.controls.email.errors && (forgotForm.controls.email.dirty || forgotForm.controls.email.touched) " class="text-danger">
                                            <div *ngIf="forgotForm.controls.email.errors.required">Please enter email address</div>
                                            <div *ngIf="forgotForm.controls.email.errors.pattern">Please enter valid email address</div>
                                        </div>
                                    </div>
                                </form>

                                <div class="col-12">
                                    <div class="d-grid">
                                        <button (click)="SendLinkClick()" class="btn btn-primary">Send Link</button>
                                    </div>
                                </div>

                                <div class="col-12 mt-3">
                                    <a routerLink="/login">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
