<section class="w-100 float-left mh">
    <header class="header w-100 float-left pt-2">
        <div class="logo">
            <a href="login"> <img alt="logo" src="../../assets/images/logo-icon.png" /></a>
        </div>
    </header>

    <div class="noheader">
        <div class="w-100 float-left p-3 text-center">
            <h5 class="font-weight-bold mb-4">Please check your email</h5>
            <p>
                A link to reset your password has been sent to: <br />
                <strong>{{this.EmailId}}</strong>
            </p>

            <p>
                If you don't receive an email shortly, check your 'bulk email' or 'junk email' folders. To make sure you receive email from Xero in the future, add the 'xero.com' domain to your email safe list. </p>
            <p>
                <button (click)="resendPasswordResetLink()" class="btn btn-link text-sky-blue font12" type="button">Try sending the password reset link again</button>
            </p>
        </div>
    </div>
</section>
