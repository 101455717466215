import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable}                                                                           from '@angular/core';
import {MatDialog}                                                                            from '@angular/material/dialog';
import {Router}                                                                               from '@angular/router';
import {Observable}                                                                           from 'rxjs';
import {tap}                                                                                  from 'rxjs/operators';
import {XeroConnectionModalComponent}                                                         from '../../core/xero-connection-modal/xero-connection-modal.component';
import {GlobalService}                                                                        from '../../global.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor
{
    public constructor(
        private globalService: GlobalService,
        private router: Router,
        public dialog: MatDialog
    )
    {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        if (req.headers.get('skip')) {
            req.headers.delete('skip');
            return next.handle(req);
        } else {
            const userId = this.globalService.getUsersID();
            const userToken = this.globalService.getUsersToken();
            const role = this.globalService.getRole();
            const companyId = this.globalService.getCompanyID();

            const headers = new HttpHeaders({
                Authorization: `Bearer ${userToken}`,
                RoleId: role.roleId.toString(),
                CompanyId: companyId.toString(),
                UserId: userId
            });

            const modifiedReq = req.clone({
                headers,
            });
            return next.handle(modifiedReq).pipe(tap(() => {
                    },
                    (err: any) => {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status !== 401) {
                                if (err.status == 403) {
                                    console.log('Error 403 from interceptor');
                                    const dialogRef = this.dialog.open(XeroConnectionModalComponent, {
                                        width: '70%',
                                        data: {}
                                    });

                                    dialogRef.afterClosed().subscribe(result => {

                                    });
                                }
                                return;
                            }
                            this.router.navigate(['/login']);
                        }
                    })
            );
        }
    }
}
