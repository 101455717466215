import {HttpClient}                    from '@angular/common/http';
import {Injectable}                    from '@angular/core';
import {Router}                        from '@angular/router';
import {Observable}                    from 'rxjs';
import {environment}                   from '../../../environments/environment';
import {XeroAddress, XeroAddressTypes} from '../models/Address';
import {BaseService}                   from './base.service';

@Injectable({
    providedIn: 'root'
})
export class AddressService extends BaseService
{
    public postCodeApiUrl: string = environment.postcodeApi.url;
    public postCodeApiKey: string = environment.postcodeApi.key;

    public constructor(
        public router: Router,
        private http: HttpClient,
    )
    {
        super(router);
    }

    public GetAddresses(postcode): Observable<any>
    {
        return this.http.get(`${this.postCodeApiUrl}/find/${postcode}?api-key=${this.postCodeApiKey}&expand=true`).pipe();
    }

    public getXeroAddress(addresses: XeroAddress[], type: XeroAddressTypes): XeroAddress
    {
        let address: XeroAddress = addresses.find(a => a.addressType === type);

        if (address.addressLine1 === null) {
            address = addresses.find(a => a.addressLine1 !== null);
        }

        return address;
    }
}
