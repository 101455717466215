import {Location}           from '@angular/common';
import {HttpParams}         from '@angular/common/http';
import {Injectable}         from '@angular/core';
import {Router}             from '@angular/router';
import {NgxSpinnerService}  from 'ngx-spinner';
import {ToastrService}      from 'ngx-toastr';
import {BehaviorSubject}    from 'rxjs/internal/BehaviorSubject';
import {PostalAddressModel} from './shared/models/Address';
import {Company}            from './shared/models/Company';
import {BaseService}        from './shared/services/base.service';

@Injectable({
    providedIn: 'root'
})
export class GlobalService extends BaseService
{
    public token: any;
    public LogincontactId: any;
    public usersName: string;
    public usersID: any;
    public usersToken: any;
    public companyName: any;
    public companyID: any;
    public companyDetails: Company;
    public accessToken: any;
    public secretToken: any;
    public contactID: any;
    public invoiceID: any;
    public vehicleID: any;
    public loginTime: any;
    public vehicleColumns: any;
    public postalAddressModel: PostalAddressModel[] = [];
    public IsEdit: boolean = false;
    public VcId: any;
    public UserRole: any;
    public permissions: any[];
    public multiUser: any;
    public notifications: any;
    public pageTitle: BehaviorSubject<string> = new BehaviorSubject('Initial Title');
    private emailId: any;

    public constructor(
        public router: Router,
        public toastr: ToastrService,
        private location: Location,
        private spinnerService: NgxSpinnerService,
    )
    {
        super(router);
    }

    public get userInitials()
    {
        return this.usersName.match(/(^\S\S?|\s\S)?/g).map(v => v.trim()).join('').match(/(^\S|\S$)?/g).join('').toLocaleUpperCase();
    }

    public setEmail(val): void
    {
        this.emailId = val;
    }

    public getEmail()
    {
        return this.emailId;
    }

    public setVehicle(val): void
    {
        localStorage.setItem('vehicleID', JSON.stringify(val));
        this.vehicleID = val;
    }

    public getVehicle()
    {
        if (this.vehicleID) {
            return this.vehicleID;
        }

        const vehicleID = localStorage.getItem('vehicleID');

        if (vehicleID) {
            try {
                this.vehicleID = JSON.parse(vehicleID);
            } catch (e) {
                localStorage.removeItem('vehicleID');
            }

            return this.vehicleID;
        }
    }

    public setVCId(val)
    {
        localStorage.setItem('VcId', JSON.stringify(val));
        this.VcId = val;
    }

    public getVCId()
    {
        if (this.VcId) {
            return this.VcId;
        }

        const VcId = localStorage.getItem('VcId');

        if (VcId) {
            try {
                this.VcId = JSON.parse(VcId);
            } catch (e) {
                localStorage.removeItem('VcId');
            }

            return this.VcId;
        }
    }

    public setPermissions(val)
    {
        localStorage.setItem('Permissions', JSON.stringify(val));
        this.permissions = val;
    }

    public getPermissions(): any
    {
        if (this.permissions) {
            return this.permissions;
        }

        let permissions: string = localStorage.getItem('Permissions');

        if (permissions) {
            try {
                permissions = JSON.parse(permissions);
            } catch (e) {
                localStorage.removeItem('Permissions');
            }

            return permissions;
        }
    }

    public setNotifications(val)
    {
        localStorage.setItem('notifications', JSON.stringify(val));
        this.notifications = val;
    }

    public getNotifications()
    {
        if (this.notifications) {
            return this.notifications;
        }

        let notifications: string = localStorage.getItem('notifications');

        if (notifications) {
            try {
                notifications = JSON.parse(notifications);
            } catch (e) {
                localStorage.removeItem('notifications');
            }

            return notifications;
        }
    }

    public setRole(val)
    {
        localStorage.setItem('userRole', JSON.stringify(val));

        this.UserRole = val;
        this.permissions = [];

        val.modules.forEach(module => {
            this.permissions = this.permissions.concat(module.permissions);
        });

        this.setPermissions(this.permissions);
    }

    public getRole()
    {
        if (this.UserRole) {
            return this.UserRole;
        }

        const UserRole: string = localStorage.getItem('userRole');

        if (UserRole) {
            try {
                this.UserRole = JSON.parse(UserRole);
            } catch (e) {
                localStorage.removeItem('userRole');
            }

            return this.UserRole;
        } else {
            return {roleId: 0};
        }
    }

    public setMultiUser(val)
    {
        localStorage.setItem('multiUser', JSON.stringify(val));
        this.multiUser = val;
    }

    public getMultiUser()
    {
        if (this.multiUser) {
            return this.multiUser;
        }

        const multiUser: string = localStorage.getItem('multiUser');

        if (multiUser) {
            try {
                this.multiUser = JSON.parse(multiUser);
            } catch (e) {
                localStorage.removeItem('multiUser');
            }

            return this.multiUser;
        }
    }

    public checkPermission(search, showMessage: boolean, previousRoute?: boolean)
    {
        this.permissions = this.getPermissions();
        const permission = this.permissions.find(x => x.permission_Name == search);

        if (typeof permission === 'undefined') {
            this.toastr.error('There has been a system malfuction, please restart your browser.', 'Unexpected Pemission', {tapToDismiss: true});
            return false;
        } else {
            if (permission.permission_Status != 1) {
                if (showMessage) {
                    this.toastr.error('You DO NOT have permission to complete the requested event, please add-contact your system admin.', 'Invalid Pemission', {tapToDismiss: true});
                }

                if (previousRoute) {
                    this.location.back();
                }

                return false;
            } else {
                return true;
            }
        }
    }

    public SetLoginTime(val)
    {
        localStorage.setItem('loginTime', JSON.stringify(val));
        this.loginTime = val;
    }

    public GetLoginTime()
    {
        if (this.loginTime) {
            return this.loginTime;
        }

        const loginTime = localStorage.getItem('loginTime');

        if (loginTime) {
            try {
                this.loginTime = JSON.parse(loginTime);
            } catch (e) {
                localStorage.removeItem('loginTime');
            }

            return this.loginTime;
        }
    }

    public setUsersName(val)
    {
        localStorage.setItem('usersName', JSON.stringify(val));
        this.usersName = val;
    }

    public getUsersName()
    {
        if (this.usersName) {
            return this.usersName;
        }

        const usersName = localStorage.getItem('usersName');

        if (usersName) {
            try {
                this.usersName = JSON.parse(usersName);
            } catch (e) {
                localStorage.removeItem('usersName');
            }

            return this.usersName;
        }
    }

    public setUsersID(val)
    {
        localStorage.setItem('usersID', JSON.stringify(val));
        this.usersID = val;
    }

    public getUsersID()
    {
        if (this.usersID) {
            return this.usersID;
        }

        const usersID: string = localStorage.getItem('usersID');

        if (usersID) {
            try {
                this.usersID = JSON.parse(usersID);
            } catch (e) {
                localStorage.removeItem('usersID');
            }

            return this.usersID;
        }
    }

    public setVehicleColumns(val)
    {
        localStorage.setItem('vehicleColumns', JSON.stringify(val));
        this.vehicleColumns = val;
    }

    public getVehicleColumns()
    {
        if (this.vehicleColumns) {
            return this.vehicleColumns;
        }

        const vehicleColumns: string = localStorage.getItem('vehicleColumns');

        if (vehicleColumns) {
            try {
                this.vehicleColumns = JSON.parse(vehicleColumns);
            } catch (e) {
                localStorage.removeItem('vehicleColumns');
            }

            return this.vehicleColumns;
        } else {
            return null;
        }
    }

    public setUsersToken(val)
    {
        localStorage.setItem('usersToken', JSON.stringify(val));
        this.usersToken = val;
    }

    public getUsersToken()
    {
        if (this.usersToken) {
            return this.usersToken;
        }

        const usersToken: string = localStorage.getItem('usersToken');

        if (usersToken) {
            try {
                this.usersToken = JSON.parse(usersToken);
            } catch (e) {
                localStorage.removeItem('usersToken');
            }

            return this.usersToken;
        } else {
            return '';
        }
    }

    public setCompanyID(val)
    {
        localStorage.setItem('companyID', JSON.stringify(val));
        this.companyID = val;
    }

    public getCompanyID()
    {
        if (this.companyID) {
            return this.companyID;
        }

        const companyID: string = localStorage.getItem('companyID');

        if (companyID) {
            try {
                this.companyID = JSON.parse(companyID);
            } catch (e) {
                localStorage.removeItem('companyID');
            }

            return this.companyID;
        }
    }

    public setCompanyName(val)
    {
        localStorage.setItem('companyName', JSON.stringify(val));
        this.companyName = val;
    }

    public getCompanyName()
    {
        if (this.companyName) {
            return this.companyName;
        }

        const companyName: string = localStorage.getItem('companyName');

        if (companyName) {
            try {
                this.companyName = JSON.parse(companyName);
            } catch (e) {
                localStorage.removeItem('companyName');
            }

            return this.companyName;
        }
    }

    public setCompanyDetails(val)
    {
        localStorage.setItem('companyDetails', JSON.stringify(val));
        this.companyDetails = val;
    }

    public getCompanyDetails()
    {
        if (this.companyDetails) {
            return this.companyDetails;
        }

        const companyDetails: string = localStorage.getItem('companyDetails');

        if (companyDetails) {
            try {
                this.companyDetails = JSON.parse(companyDetails);
            } catch (e) {
                localStorage.removeItem('companyDetails');
            }

            return this.companyDetails;
        }
    }

    public setToken(token)
    {
        this.token = token;
    }

    public getToken()
    {
        return this.token;
    }

    //  ----------------------------------For setting and getting LogincontactId value-----------------------------------------------
    public setLoginContactid(contactID)
    {
        this.LogincontactId = contactID;
    }

    public getLoginConatctId()
    {
        return this.LogincontactId;
    }

    //  ----------------------------------For setting and getting access and secret token -----------------------------------------------
    public setAccessToken(accessToken, secretToken)
    {
        this.accessToken = accessToken;
        this.secretToken = secretToken;
    }

    public getAccessToken()
    {
        return this.accessToken;
    }

    public getSecretToken()
    {
        return this.secretToken;
    }

    //  ----------------------------------For setting and getting contactId value-----------------------------------------------

    public setContactID(contactID)
    {
        this.contactID = contactID;
    }

    public getContactID()
    {
        return this.contactID;
    }

    //  ----------------------------------For setting and getting invoiceId value for Invoice -----------------------------------------------
    public setInvoiceID(val)
    {
        localStorage.setItem('invoiceID', JSON.stringify(val));
        this.invoiceID = val;
    }

    public getInvoiceID()
    {
        if (this.invoiceID) {
            return this.invoiceID;
        }

        const invoiceID: string = localStorage.getItem('invoiceID');

        if (invoiceID) {
            try {
                this.invoiceID = JSON.parse(invoiceID);
            } catch (e) {
                localStorage.removeItem('invoiceID');
            }

            return this.invoiceID;
        }
    }

    // -------------------------------------For set and get postal address-----------------------------------
    public setpostalAddress(data)
    {
        this.postalAddressModel = data;
    }

    public getpostalAddress()
    {
        return this.postalAddressModel;
    }


    // ------------------------------------for set and get header value-----------------------------------


    // -----------------------------------for set and get isEdit value--------------------------------

    public setIsEdit(value: boolean)
    {
        this.IsEdit = value;
    }

    public getIsEdit()
    {
        return this.IsEdit;
    }

    // -----------------------------------for set and get page_title value--------------------------------

    public setpagetitle(value: string)
    {
        this.pageTitle.next(value);
    }

    public getpagetitle()
    {
        return this.pageTitle;
    }


    public RequestError(res: any)
    {
        this.spinnerService.hide();
        if (res.status == 401) {
            window.location.href = res.error;
        }

        if (res.status == 400 || res.status == 404) {
            this.router.navigateByUrl('/error');
        }
    }

    public convertToQueryParams(rawQueryParams)
    {
        let params = new HttpParams();
        if (rawQueryParams) {
            rawQueryParams.forEach(param => {
                params = params.set(param.name, param.value);
            });
        }

        return params;
    }

    public formatDate(dateObj)
    {
        return new Date(dateObj.year, dateObj.month, dateObj.day);
    }
}
