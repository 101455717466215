<div class="wrapper">
	<div class="authentication-header area">
		<ul class="circles">
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
			<li></li>
		</ul>
	</div>
	<div class="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
		<div class="container">
			<div class="row row-cols-1 row-cols-lg-1 row-cols-xl-1">
				<div class="col mx-auto">
					<div class="card">
						<div class="card-body">
							<div class="rounded">
								<div class="text-center">
									<a routerLink="/login"><img src="assets/images/MTALogoNoBackground-01.png"
											style="width: 25em;" alt="" /></a>
									<h3 *ngIf="userForm != true" class="">Sign Up MTA-Plus FREE for One month!</h3>
									<div *ngIf="userForm != true" class="text">
										<p>Every feature, unlimited users, no credit card required.</p>
									</div>
									<p *ngIf="userForm != true">Already have an account? <a routerLink="/login">Sign in
											here</a>
									</p>
								</div>
								<div class="form-body">

									<!-- <div class="w-100 float-left p-3 text-center">
											<h2 class="title title-2">Sign Up MTA-Plus FREE for One month! </h2>
											<div class="text">
												<p>Every feature, unlimited users, no credit card required.</p>
											</div>
										</div> -->

									<div class="row" *ngIf="userForm != true">
										<div class="col-6" style="border-right: 2px solid rgba(0, 0, 0, 0.1);">
											<h3 class="register-choice">Register as a company</h3>
											<p class="register-choice">For a company that is not yet registered
												registered</p>
											<br>
											<button class="btn btn-primary button-orientation"
												(click)="userForm = true; FullRegister = true">Register as
												Company</button>
										</div>

										<div class="col-6">
											<h3 class="register-choice">Register as a user</h3>
											<p class="register-choice">For a currently registered company</p>
											<br>
											<button class="btn btn-primary button-orientation"
												(click)="userForm = true; FullRegister = false">Register as
												User {{FullRegister}}</button>
										</div>
									</div>

									<div class="row" *ngIf="userForm && FullRegister == false">
										<mat-horizontal-stepper #stepper class="col-12" [linear]="true">

											<mat-step [stepControl]="UserCompanyDetails">
												<form [formGroup]="UserCompanyDetails">
													<ng-template matStepLabel>Company Mapping</ng-template>
													<div class="p-5">
														<div class="row">
															<div class="col-6">
																<label style="display: block;">User Email</label>
																<input class="form-control" formControlName="Email"
																	placeholder="Email" required
																	style="display: inline-block; width: 90%;">
															</div>
															<div class="col-6">
																<label>Company Mapping ID</label>
																<div class="input-group">
																	<input class="form-control"
																		formControlName="companyMappingId"
																		placeholder="Company Mapping Id" required>
																	<div class="input-group-append">
																		<button class="btn btn-primary"
																			(click)="findCompany()">Find
																			Company</button>
																	</div>
																</div>
															</div>
															<div class="col-6" *ngIf="selectedCompany">
																<h4>Company Details</h4>
																<p class="companyDisplayInfo"><strong>Company
																		Name:</strong>
																	{{selectedCompany.company_Name}}</p>
																<p class="companyDisplayInfo"><strong>Phone
																		Number:</strong>
																	{{selectedCompany.phone}}</p>
																<p class="companyDisplayInfo"><strong>Address:</strong>
																</p>
																<p class="companyDisplayInfo">
																	{{selectedCompany.address_Line_1}}</p>
																<p class="companyDisplayInfo">
																	{{selectedCompany.address_Line_2}}</p>
																<p class="companyDisplayInfo">
																	{{selectedCompany.city_Town}}</p>
																<p class="companyDisplayInfo">
																	{{selectedCompany.postcode}}</p>
																<p class="companyDisplayInfo">{{selectedCompany.region}}
																</p>
															</div>
														</div>

														<div class="row" *ngIf="multiCompany">
															<div class="col-md-12">
																<form [formGroup]="AccountPairingForm">
																	<br>
																	<p>There is already an account using
																		{{this.UserCompanyDetails.get('Email').value}},
																		if this is
																		incorrect please contact MTA Support.</p>
																	<br>
																	<p>If you would like to link your existing account
																		with this company please
																		enter your password.</p>
																	<label style="display: block;">Password</label>
																	<input class="form-control"
																		formControlName="Password"
																		placeholder="Password" type="password" required
																		style="display: inline-block; width: 90%;">
																</form>
															</div>
														</div>
													</div>

													<div class="row" style="margin-top: 25px;">
														<button *ngIf="!multiCompany" class="btn btn-primary w-25"
															mat-button matStepperNext style="margin: 0 auto;"
															(click)="transferEmail()">Next</button>
														<button *ngIf="multiCompany" class="btn btn-primary w-25"
															mat-button matStepperNext style="margin: 0 auto;"
															(click)="LinkCompanyWithAccount()">Link account with this
															company</button>
													</div>

												</form>
											</mat-step>

											<mat-step [stepControl]="UserDetails" *ngIf="!multiCompany">
												<form [formGroup]="UserDetails">
													<ng-template matStepLabel>User Details</ng-template>
													<div class="p-5-sm">
														<div class="row">
															<div class="col-md-6 col-sm-12">
																<label style="display: block;">First Name</label>
																<input class="form-control"
																	formControlName="contactFirstName"
																	placeholder="First Name">
																<div class="text-danger"
																	*ngIf="UserDetails.controls.contactFirstName.errors && (UserDetails.controls.contactFirstName.dirty || UserDetails.controls.contactFirstName.touched) ">
																	<div *ngIf="UserDetails.controls.contactFirstName.errors.required"
																		padding>
																		*Please enter contact First Name .
																	</div>
																</div>
															</div>
															<div class="col-md-6 col-sm-12">
																<label style="display: block;">Last Name</label>
																<input class="form-control"
																	formControlName="contactLastName"
																	placeholder="Last Name">
																<div class="text-danger"
																	*ngIf="UserDetails.controls.contactLastName.errors && (UserDetails.controls.contactLastName.dirty || UserDetails.controls.contactLastName.touched) ">
																	<div *ngIf="UserDetails.controls.contactLastName.errors.required"
																		padding>
																		*Please enter contact Last Name .
																	</div>
																</div>
															</div>
															<div class="col-md-6 col-sm-12">
																<label style="display: block;">Email</label>
																<input class="form-control"
																	formControlName="emailAddress"
																	placeholder="Email Address">
																<div class="text-danger"
																	*ngIf="UserDetails.controls.emailAddress.errors && (UserDetails.controls.emailAddress.dirty || UserDetails.controls.emailAddress.touched) ">
																	<div *ngIf="UserDetails.controls.emailAddress.errors.required"
																		padding>
																		*Please enter email Address .
																	</div>
																	<div *ngIf="UserDetails.controls.emailAddress.errors.pattern"
																		padding>
																		*Please enter valid email address.
																	</div>
																</div>
															</div>

															<div class="col-md-6 col-sm-12">
																<label style="display: block;">Phone</label>
																<input class="form-control"
																	formControlName="phoneNumber" mask="00000 000000"
																	placeholder="Phone number">
																<div class="text-danger"
																	*ngIf="UserDetails.controls.phoneNumber.errors && (UserDetails.controls.phoneNumber.dirty || UserDetails.controls.phoneNumber.touched) ">
																	<div *ngIf="UserDetails.controls.phoneNumber.errors.required"
																		padding>
																		*Please enter phone number .
																	</div>
																	<div *ngIf="UserDetails.controls.phoneNumber.errors.minLength"
																		padding>
																		*Please enter valid phone Number.
																	</div>
																	<div *ngIf="UserDetails.controls.phoneNumber.errors.pattern"
																		padding>
																		*Please enter valid phone Number.
																	</div>
																</div>
															</div>
															<div class="col-md-6 col-sm-12">
																<label style="display: block;">Password <i
																		class="fas fa-info-circle"
																		matTooltip="Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, a number and a special character."></i></label>
																<input type="password" class="form-control"
																	formControlName="password"
																	(keyup)="passwordChange()" placeholder="Password">
																<!-- <password-strength-meter [password]="password">
																</password-strength-meter> -->
																<div class="text-danger"
																	*ngIf="UserDetails.controls.password.errors && (UserDetails.controls.password.dirty || UserDetails.controls.password.touched) ">
																	<div *ngIf="UserDetails.controls.password.errors.required"
																		padding>
																		*Please enter password.
																	</div>
																	<div *ngIf="UserDetails.controls.password.errors.pattern"
																		padding>
																		*Please enter valid password.
																	</div>
																</div>
															</div>
															<div class="col-md-6 col-sm-12">
																<label style="display: block;">Confirm Password</label>
																<input type="password" class="form-control"
																	formControlName="confirmPassword"
																	placeholder="Confirm Password">
																<div class="text-danger"
																	*ngIf="UserDetails.controls.confirmPassword.errors && (UserDetails.controls.confirmPassword.dirty || UserDetails.controls.confirmPassword.touched) ">
																	<div *ngIf="UserDetails.controls.confirmPassword.errors.required"
																		padding>
																		*Please confirm password.
																	</div>
																	<div *ngIf="UserDetails.controls.confirmPassword.hasError('notSame')"
																		padding>
																		*Please enter the matching password.
																	</div>
																</div>
															</div>

														</div>
													</div>

													<div class="row" style="margin-top: 25px;">
														<div class="tc-section">
															<button class="btn btn-dark m-1" mat-button
																matStepperPrevious>Back</button>
															<button class="btn btn-primary m-1" mat-button
																matStepperNext>Next</button>
														</div>
													</div>

												</form>
											</mat-step>

											<mat-step [stepControl]="ConfirmDetails" *ngIf="!multiCompany">
												<ng-template matStepLabel>Confirm Details</ng-template>
												<div class="mx-auto row" style="width: max-content;">
													<!-- <div class="row"> -->
													<!-- <div class="col-6"> -->
													<div class="p-4 confirm-details-desktop small-screen-sm"
														*ngIf="selectedCompany">
														<h4>Company Details</h4>
														<p class="companyDisplayInfo"><strong>First Name:</strong>
															{{selectedCompany.company_Name != undefined ?
															selectedCompany.company_Name : ''}}</p>
														<p class="companyDisplayInfo"><strong>Phone Number:</strong>
															{{selectedCompany.phone !=
															undefined ?
															selectedCompany.phone : ''}}</p>
														<p class="companyDisplayInfo"><strong>Address:</strong></p>
														<p class="companyDisplayInfo">
															{{selectedCompany.address_Line_1 != undefined ?
															selectedCompany.address_Line_1 : ''}}</p>
														<p class="companyDisplayInfo">
															{{selectedCompany.address_Line_2 != undefined ?
															selectedCompany.address_Line_2 : ''}}</p>
														<p class="companyDisplayInfo">{{selectedCompany.city_Town !=
															undefined ?
															selectedCompany.city_Town :
															''}}</p>
														<p class="companyDisplayInfo">{{selectedCompany.postcode !=
															undefined ?
															selectedCompany.postcode : ''}}
														</p>
														<p class="companyDisplayInfo">{{selectedCompany.region !=
															undefined ?
															selectedCompany.region : ''}}</p>
													</div>

													<div class="p-4 confirm-details-desktop small-screen-sm">
														<h4>User Details</h4>
														<p class="companyDisplayInfo"><strong>First Name:</strong>
															{{UserDetails.get('contactFirstName').value}}
														</p>
														<p class="companyDisplayInfo"><strong>Last Name:</strong>
															{{UserDetails.get('contactLastName').value}}
														</p>
														<p class="companyDisplayInfo"><strong>Email
																Address:</strong>
															{{UserDetails.get('emailAddress').value}}
														</p>
														<p class="companyDisplayInfo"><strong>Phone Number:</strong>
															{{UserDetails.get('phoneNumber').value}}
														</p>
													</div>
												</div>

												<form [formGroup]="ConfirmDetails">
													<div class="md-form md-outline form-lg w-100 float-left my-3">
														<div class="captcha-section">
															<ngx-recaptcha2 #captchaElem [siteKey]="siteKey"
																[useGlobalDomain]="false" formControlName="recaptcha">
															</ngx-recaptcha2>
														</div>
													</div>
													<div class="my-3 tc-section">
														<label class="form-label text">
															<input type="checkbox" name="TermsAccepted" value="true"
																required formControlName="check">
															I have read and I agree to the
															<a href="https://spreadsheetmtd.co.uk/Home/Eula"
																target="_blank">terms of use</a>,
															<a href="https://spreadsheetmtd.co.uk/Home/PrivacyNotice/"
																target="_blank">privacy
																notice.</a>
														</label>
													</div>
												</form>

												<div class="row">
													<div class="tc-section">
														<button class="btn btn-dark m-1" mat-button
															matStepperPrevious>Back</button>
														<button class="btn btn-primary m-1" mat-button matStepperNext
															(click)="submitUserApplication()">Confirm
															Details</button>
													</div>
												</div>
											</mat-step>

										</mat-horizontal-stepper>
									</div>

									<div class="row" *ngIf="userForm && FullRegister == true">
										<mat-horizontal-stepper #stepper class="col-12" [linear]="true">

											<mat-step [stepControl]="companyForm">
												<img (click)="connectToXero()"
												src="../../../assets/images/connect-blue.svg">
												<form [formGroup]="companyForm">
													<ng-template matStepLabel>Company Details</ng-template>
													<div class="row">
														<div class="col-md-6 col-sm-12">
															<div class="form-group w-100 float-left">
																<label for="">Company Name <span
																		class="required">*</span></label>
																<input type="text" class="form-control"
																	placeholder="Company Name"
																	formControlName="Company_Name"
																	[ngClass]="{ 'is-invalid': submitted && companyForm.controls.Company_Name.errors }">
																<div *ngIf="submitted && companyForm.controls.Company_Name.errors"
																	class="invalid-feedback">
																	<div
																		*ngIf="companyForm.controls.Company_Name.errors.required">
																		Company Name is required</div>
																</div>
															</div>

															<div class="form-group w-100 float-left">
																<label for="" style="display: block;">Primary Contact
																	<span class="required">*</span></label>
																<input type="text" class="form-control w-50 float-left"
																	style="display: inline-block;" placeholder="First"
																	formControlName="First_Name"
																	[ngClass]="{ 'is-invalid': submitted && companyForm.controls.First_Name.errors }">
																<input type="text" class="form-control w-50 float-left"
																	style="display: inline-block;" placeholder="Last"
																	formControlName="Last_Name"
																	[ngClass]="{ 'is-invalid': submitted && companyForm.controls.Last_Name.errors }">
																<div *ngIf="submitted && companyForm.controls.First_Name.errors"
																	class="w-50 float-left invalid-feedback">
																	<div
																		*ngIf="companyForm.controls.First_Name.errors.required">
																		First Name
																		is required</div>
																</div>
																<div *ngIf="submitted && companyForm.controls.Last_Name.errors"
																	class="w-50 float-left invalid-feedback">
																	<div
																		*ngIf="companyForm.controls.Last_Name.errors.required">
																		Last Name is
																		required</div>
																</div>
															</div>
															<div class="form-group w-100 float-left">
																<label for="">Email <span
																		class="required">*</span></label>
																<input type="text" class="form-control"
																	placeholder="Email" formControlName="Email"
																	[ngClass]="{ 'is-invalid': submitted && companyForm.controls.Email.errors }">
																<div *ngIf="submitted && companyForm.controls.Email.errors"
																	class="w-50 float-left invalid-feedback">
																	<div
																		*ngIf="companyForm.controls.Email.errors.required">
																		Email is
																		required</div>
																	<div
																		*ngIf="companyForm.controls.Email.errors.email">
																		Please enter a
																		valid email</div>
																</div>
															</div>
															<div class="form-group w-100 float-left">
																<label for="">Mobile <span
																		class="required">*</span></label>
																<input type="text" class="form-control"
																	placeholder="Number" mask="00000 000000"
																	formControlName="Mobile"
																	[ngClass]="{ 'is-invalid': submitted && companyForm.controls.Mobile.errors }">
																<div *ngIf="submitted && companyForm.controls.Mobile.errors"
																	class="w-50 float-left invalid-feedback">
																	<div
																		*ngIf="companyForm.controls.Mobile.errors.required">
																		Mobile Number
																		is required</div>
																</div>
															</div>
														</div>

														<div class="col-md-6 col-sm-12">
															<div class="form-group w-100 float-left">
																<label for="">Phone</label>
																<input type="text" class="form-control"
																	placeholder="Number" mask="99999 999999"
																	formControlName="Phone">
															</div>
															<div class="form-group w-100 float-left">
																<label for="">Skype Name/Number</label>
																<input type="text" class="form-control"
																	placeholder="Skype Name/Number"
																	formControlName="Skype">
															</div>
															<div class="form-group w-100 float-left">
																<label for="">Website</label>
																<input type="text" class="form-control"
																	placeholder="http://" formControlName="Website">

															</div>
														</div>

														<div class="col-12">
															<hr>
														</div>

														<div class="col-md-6 col-sm-12">
															<div class="form-group w-100 float-left">
																<h3>Postal Address *</h3>
																<div class="input-group float-left">
																	<input type="text" class="form-control"
																		placeholder="Postcode"
																		formControlName="Postal_Postcode"
																		oninput="this.value = this.value.toUpperCase()">
																	<div class="input-group-append">
																		<button type="submit" class="btn btn-primary"
																			(click)="GetPostalAddress()"><i
																				class="fa fa-search-location"></i>
																			Search</button>
																	</div>
																</div>
															</div>

															<div class="form-group w-100 float-left"
																*ngIf="postalPcValid == true">
																<mat-select class="form-control"
																	formControlName="Selected_Address"
																	placeholder="Please select address"
																	(selectionChange)="onChange($event.value)">
																	<mat-option
																		*ngFor="let address of postalAddresses; let i = index;"
																		[value]="i">
																		{{address.line_1}},
																		{{address.locality}}</mat-option>
																</mat-select>
															</div>

															<div class="form-group w-100 float-left"
																*ngIf="postalSearch">
																<hr>
																<input type="text" class="form-control w-100 float-left"
																	placeholder="Address Line 1"
																	formControlName="Address_Line_1"
																	[ngClass]="{ 'is-invalid': submitted && f.Address_Line_1.errors }">
																<div *ngIf="submitted && f.Address_Line_1.errors"
																	class="w-50 float-left invalid-feedback">
																	<div *ngIf="f.Address_Line_1.errors.required">
																		Address Line 1 is
																		required</div>
																</div>
															</div>
															<div class="form-group w-100 float-left"
																*ngIf="postalSearch">
																<input type="text" class="form-control w-100 float-left"
																	placeholder="Address Line 2"
																	formControlName="Address_Line_2"
																	[ngClass]="{ 'is-invalid': submitted && f.Address_Line_2.errors }">
																<div *ngIf="submitted && f.Address_Line_2.errors"
																	class="w-50 float-left invalid-feedback">
																	<div *ngIf="f.Address_Line_2.errors.required">
																		Address Line 2 is
																		required</div>
																</div>
															</div>
															<div class="form-group w-100 float-left"
																*ngIf="postalSearch">
																<input type="text" class="form-control w-100 float-left"
																	placeholder="City / Town"
																	formControlName="City_Town"
																	[ngClass]="{ 'is-invalid': submitted && f.City_Town.errors }">
																<div *ngIf="submitted && f.City_Town.errors"
																	class="w-50 float-left invalid-feedback">
																	<div *ngIf="f.City_Town.errors.required">
																		City / Town is required
																	</div>
																</div>
															</div>
															<div class="form-group w-100 float-left"
																*ngIf="postalSearch">
																<input type="text" class="form-control w-75 float-left"
																	placeholder="Region" formControlName="Region"
																	[ngClass]="{ 'is-invalid': submitted && f.Region.errors }">
																<input type="text" class="form-control w-25 float-left"
																	placeholder="Postcode" formControlName="Postcode"
																	[ngClass]="{ 'is-invalid': submitted && f.Postcode.errors }">
																<div *ngIf="submitted && f.Region.errors"
																	class="w-75 float-left invalid-feedback">
																	<div *ngIf="f.Region.errors.required">
																		Region is required</div>
																</div>
																<div *ngIf="submitted && f.Postcode.errors"
																	class="w-25 float-left invalid-feedback">
																	<div *ngIf="f.Postcode.errors.required">
																		Postcode is required</div>
																</div>
															</div>
															<div class="form-group w-100 float-left"
																*ngIf="postalSearch">
																<input type="text" class="form-control"
																	placeholder="Country" formControlName="Country"
																	[ngClass]="{ 'is-invalid': submitted && f.Country.errors }">
																<div *ngIf="submitted && f.Country.errors"
																	class="w-25 float-left invalid-feedback">
																	<div *ngIf="f.Country.errors.required">
																		Country is required</div>
																</div>
															</div>
														</div>


														<div class="col-md-6 col-sm-12">
															<div class="form-group w-100 float-left">
																<h3 style="display: inline-block;">Registered Address *
																</h3>
																<mat-checkbox class="float-right mt-1"
																	(change)="AddressReplicate($event)">Same
																	as postal address
																</mat-checkbox>
																<!-- <div class="form-group float-right">
																	
																</div> -->
																<div class="form-group float-left">
																	<div class="input-group float-left">
																		<input type="text" class="form-control"
																			placeholder="Postcode"
																			formControlName="Registered_Postcode_Input"
																			oninput="this.value = this.value.toUpperCase()">
																		<div class="input-group-append">
																			<button type="submit"
																				class="btn btn-primary mr-1 px-3"
																				(click)="GetRegisteredAddress()"><i
																					class="fa fa-search-location"></i>
																				Search</button>
																		</div>
																	</div>
																</div>

																<div class="form-group w-100 float-left"
																	*ngIf="registeredPcValid == true">
																	<mat-select class="form-control"
																		formControlName="Registered_Selected_Address"
																		placeholder="Please select address"
																		(selectionChange)="onChangeRegistered($event.value)">
																		<mat-option
																			*ngFor="let address of registeredAddresses; let i = index;"
																			[value]="i">
																			{{address.line_1}},
																			{{address.locality}}</mat-option>
																	</mat-select>
																</div>

																<div class="form-group w-100 float-left"
																	*ngIf="registerdSearch">
																	<hr>
																	<input type="text"
																		class="form-control w-100 float-left"
																		placeholder="Address Line 1"
																		formControlName="Registered_Address_Line_1"
																		[ngClass]="{ 'is-invalid': submitted && f.Registered_Address_Line_1.errors }">
																	<div *ngIf="submitted && f.Registered_Address_Line_1.errors"
																		class="w-25 float-left invalid-feedback">
																		<div
																			*ngIf="f.Registered_Address_Line_1.errors.required">
																			Address
																			Line 1 is required</div>
																	</div>
																</div>
																<div class="form-group w-100 float-left"
																	*ngIf="registerdSearch">
																	<input type="text"
																		class="form-control w-100 float-left"
																		placeholder="Address Line 2"
																		formControlName="Registered_Address_Line_2"
																		[ngClass]="{ 'is-invalid': submitted && f.Registered_Address_Line_2.errors }">
																	<div *ngIf="submitted && f.Registered_Address_Line_2.errors"
																		class="w-25 float-left invalid-feedback">
																		<div
																			*ngIf="f.Registered_Address_Line_2.errors.required">
																			Address
																			Line 2 is required</div>
																	</div>
																</div>
																<div class="form-group w-100 float-left"
																	*ngIf="registerdSearch">
																	<input type="text"
																		class="form-control w-100 float-left"
																		placeholder="City / Town"
																		formControlName="Registered_City_Town"
																		[ngClass]="{ 'is-invalid': submitted && f.Registered_City_Town.errors }">
																	<div *ngIf="submitted && f.Registered_City_Town.errors"
																		class="w-25 float-left invalid-feedback">
																		<div
																			*ngIf="f.Registered_City_Town.errors.required">
																			City / Town is
																			required</div>
																	</div>
																</div>
																<div class="form-group w-100 float-left"
																	*ngIf="registerdSearch">
																	<input type="text"
																		class="form-control w-75 float-left"
																		placeholder="Region"
																		formControlName="Registered_Region"
																		[ngClass]="{ 'is-invalid': submitted && f.Registered_Region.errors }">
																	<input type="text"
																		class="form-control w-25 float-left"
																		placeholder="Postcode"
																		formControlName="Registered_Postcode"
																		[ngClass]="{ 'is-invalid': submitted && f.Registered_Postcode.errors }">
																	<div *ngIf="submitted && f.Registered_Region.errors"
																		class="w-75 float-left invalid-feedback">
																		<div
																			*ngIf="f.Registered_Region.errors.required">
																			Region is required
																		</div>
																	</div>
																	<div *ngIf="submitted && f.Registered_Postcode.errors"
																		class="w-25 float-left invalid-feedback">
																		<div
																			*ngIf="f.Registered_Postcode.errors.required">
																			Postcode is
																			required</div>
																	</div>
																</div>
																<div class="form-group w-100 float-left"
																	*ngIf="registerdSearch">
																	<input type="text" class="form-control"
																		placeholder="Country"
																		formControlName="Registered_Country"
																		[ngClass]="{ 'is-invalid': submitted && f.Registered_Country.errors }">
																	<div *ngIf="submitted && f.Registered_Country.errors"
																		class="w-25 float-left invalid-feedback">
																		<div
																			*ngIf="f.Registered_Country.errors.required">
																			Country is
																			required</div>
																	</div>
																</div>
															</div>
														</div>

													</div>

													<div class="row" style="margin-top: 25px;">
														<button class="btn btn-primary w-25" mat-button matStepperNext
															style="margin: 0 auto;">Next</button>
													</div>

												</form>
											</mat-step>

											<mat-step [stepControl]="UserDetails">
												<form [formGroup]="UserDetails">
													<ng-template matStepLabel>User Details</ng-template>
													<div class="p-5-sm">
														<div class="row">
															<div class="col-md-6 col-sm-12">
																<label style="display: block;">First Name</label>
																<input class="form-control"
																	formControlName="contactFirstName"
																	placeholder="First Name">
																<div class="text-danger"
																	*ngIf="UserDetails.controls.contactFirstName.errors && (UserDetails.controls.contactFirstName.dirty || UserDetails.controls.contactFirstName.touched) ">
																	<div *ngIf="UserDetails.controls.contactFirstName.errors.required"
																		padding>
																		*Please enter contact First Name .
																	</div>
																</div>
															</div>
															<div class="col-md-6 col-sm-12">
																<label style="display: block;">Last Name</label>
																<input class="form-control"
																	formControlName="contactLastName"
																	placeholder="Last Name">
																<div class="text-danger"
																	*ngIf="UserDetails.controls.contactLastName.errors && (UserDetails.controls.contactLastName.dirty || UserDetails.controls.contactLastName.touched) ">
																	<div *ngIf="UserDetails.controls.contactLastName.errors.required"
																		padding>
																		*Please enter contact Last Name .
																	</div>
																</div>
															</div>
															<div class="col-md-6 col-sm-12">
																<label style="display: block;">Email</label>
																<input class="form-control"
																	formControlName="emailAddress"
																	placeholder="Email Address">
																<div class="text-danger"
																	*ngIf="UserDetails.controls.emailAddress.errors && (UserDetails.controls.emailAddress.dirty || UserDetails.controls.emailAddress.touched) ">
																	<div *ngIf="UserDetails.controls.emailAddress.errors.required"
																		padding>
																		*Please enter email Address .
																	</div>
																	<div *ngIf="UserDetails.controls.emailAddress.errors.pattern"
																		padding>
																		*Please enter valid email address.
																	</div>
																</div>
															</div>
															<div class="col-md-6 col-sm-12">
																<label style="display: block;">Phone</label>
																<input class="form-control"
																	formControlName="phoneNumber" mask="00000 000000"
																	placeholder="Phone number">
																<div class="text-danger"
																	*ngIf="UserDetails.controls.phoneNumber.errors && (UserDetails.controls.phoneNumber.dirty || UserDetails.controls.phoneNumber.touched) ">
																	<div *ngIf="UserDetails.controls.phoneNumber.errors.required"
																		padding>
																		*Please enter phone number .
																	</div>
																	<div *ngIf="UserDetails.controls.phoneNumber.errors.minLength"
																		padding>
																		*Please enter valid phone Number.
																	</div>
																	<div *ngIf="UserDetails.controls.phoneNumber.errors.pattern"
																		padding>
																		*Please enter valid phone Number.
																	</div>
																</div>
															</div>
															<div class="col-md-6 col-sm-12">
																<label style="display: block;">Password <i
																		class="fas fa-info-circle"
																		matTooltip="Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, a number and a special character."></i></label>
																<input type="password" class="form-control"
																	formControlName="password"
																	(keyup)="passwordChange()" placeholder="Password">
																<!-- <password-strength-meter [password]="password">
																</password-strength-meter> -->
																<div class="text-danger"
																	*ngIf="UserDetails.controls.password.errors && (UserDetails.controls.password.dirty || UserDetails.controls.password.touched) ">
																	<div *ngIf="UserDetails.controls.password.errors.required"
																		padding>
																		*Please enter password.
																	</div>
																	<div *ngIf="UserDetails.controls.password.errors.pattern"
																		padding>
																		*Please enter valid password.
																	</div>
																</div>
															</div>
															<div class="col-md-6 col-sm-12">
																<label style="display: block;">Confirm Password</label>
																<input type="password" class="form-control"
																	formControlName="confirmPassword"
																	placeholder="Confirm Password">
																<div class="text-danger"
																	*ngIf="UserDetails.controls.confirmPassword.errors && (UserDetails.controls.confirmPassword.dirty || UserDetails.controls.confirmPassword.touched) ">
																	<div *ngIf="UserDetails.controls.confirmPassword.errors.required"
																		padding>
																		*Please confirm password.
																	</div>
																	<div *ngIf="UserDetails.controls.confirmPassword.hasError('notSame')"
																		padding>
																		*Please enter the matching password.
																	</div>
																</div>
															</div>

														</div>
													</div>

													<div class="row">
														<div class="tc-section">
															<button class="btn btn-dark m-1" mat-button
																matStepperPrevious>Back</button>
															<button class="btn btn-primary m-1" mat-button
																matStepperNext>Next</button>
														</div>
													</div>

												</form>
											</mat-step>

											<mat-step [stepControl]="ConfirmDetails">
												<ng-template matStepLabel>Confirm Details</ng-template>
												<div class="mx-auto row" style="width: max-content;">
													<!-- <div class="row"> -->
													<!-- <div class="col-6"> -->
													<div class="p-4 confirm-details-desktop small-screen-sm">
														<h4>Company Details</h4>
														<p class="companyDisplayInfo"><strong>Company
																Name:</strong>
															{{companyForm.controls.Company_Name.value
															!= undefined ?
															companyForm.controls.Company_Name.value :
															''}}</p>
														<p class="companyDisplayInfo"><strong>Mobile
																Number:</strong>
															{{companyForm.controls.Mobile.value !=
															undefined ? companyForm.controls.Mobile.value : ''}}
														</p>
														<p class="companyDisplayInfo"><strong>Phone
																Number:</strong>
															{{companyForm.controls.Phone.value !=
															undefined ? companyForm.controls.Phone.value : ''}}
														</p>
														<p class="companyDisplayInfo"><strong>Email
																Address:</strong>
															{{companyForm.controls.Email.value !=
															undefined ? companyForm.controls.Email.value : ''}}
														</p>
														<p class="companyDisplayInfo"><strong>Address:</strong>
														</p>
														<p class="companyDisplayInfo">
															{{companyForm.controls.Address_Line_1.value !=
															undefined
															?
															companyForm.controls.Address_Line_1.value : ''}}</p>
														<p class="companyDisplayInfo">
															{{companyForm.controls.Address_Line_2.value !=
															undefined
															?
															companyForm.controls.Address_Line_2.value : ''}}</p>
														<p class="companyDisplayInfo">
															{{companyForm.controls.City_Town.value != undefined
															?
															companyForm.controls.City_Town.value : ''}}</p>
														<p class="companyDisplayInfo">
															{{companyForm.controls.Region.value != undefined ?
															companyForm.controls.Region.value : ''}}</p>
														<p class="companyDisplayInfo">
															{{companyForm.controls.Postcode.value != undefined ?
															companyForm.controls.Postcode.value : ''}}</p>
													</div>
													<!-- </div> -->

													<!-- <div class="col-6"> -->
													<div class="p-4 confirm-details-desktop small-screen-sm">
														<h4>User Details</h4>
														<p class="companyDisplayInfo"><strong>First
																Name:</strong>
															{{UserDetails.get('contactFirstName').value}}
														</p>
														<p class="companyDisplayInfo"><strong>Last
																Name:</strong>
															{{UserDetails.get('contactLastName').value}}
														</p>
														<p class="companyDisplayInfo"><strong>Email
																Address:</strong>
															{{UserDetails.get('emailAddress').value}}
														</p>
														<p class="companyDisplayInfo"><strong>Phone
																Number:</strong>
															{{UserDetails.get('phoneNumber').value}}
														</p>
													</div>
													<!-- </div> -->
												</div>
												<!-- </div> -->

												<form [formGroup]="ConfirmDetails">
													<div class="md-form md-outline form-lg w-100 float-left my-3">
														<div class="captcha-section">
															<ngx-recaptcha2 #captchaElem [siteKey]="siteKey"
																[useGlobalDomain]="false" formControlName="recaptcha">
															</ngx-recaptcha2>
														</div>
													</div>
													<div class="my-3 tc-section">
														<label class="form-label text">
															<input type="checkbox" name="TermsAccepted" value="true"
																required formControlName="check">
															I have read and I agree to the
															<a href="https://spreadsheetmtd.co.uk/Home/Eula"
																target="_blank">terms
																of use</a>,
															<a href="https://spreadsheetmtd.co.uk/Home/PrivacyNotice/"
																target="_blank">privacy
																notice.</a>
														</label>
													</div>
												</form>

												<div class="row">
													<div class="tc-section">
														<button class="btn btn-dark m-1" mat-button
															matStepperPrevious>Back</button>
														<button class="btn btn-primary m-1" (click)="SubmitFullSignUp()"
															mat-button>Confirm
															Details</button>
													</div>
												</div>
											</mat-step>

										</mat-horizontal-stepper>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>