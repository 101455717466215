import {Injectable}     from '@angular/core';
import {Router}         from '@angular/router';
import {Observable, of} from 'rxjs';
import {environment}    from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseService
{
    protected apiUrl: string = environment.api.url;

    protected constructor(
        public router: Router
    )
    {
    }

    public handleError<T>(result?: T)
    {
        return (error: any): Observable<T> => {
            console.error(error);

            if (error.status === 401) {
                window.location.href = error.error;
            }

            if (error.status === 400 || error.status === 404) {
                this.router.navigateByUrl('/error');
            }

            return of(result as T);
        };
    }
}
