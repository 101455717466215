<section class="w-100 float-left mh">
    <header class="header w-100 float-left pt-2">
        <div class="logo">
            <a href="login"> <img alt="logo" src="../../assets/images/logo-icon.png" /></a>
        </div>
    </header>

    <div class="noheader">
        <div class="px-4 w-100 float-left">
            <div class="forgot_form border p-4 bg-white clearfix wrapper">
                <div class="log_fields">
                    <form [formGroup]="setPasswordForm">
                        <h2 class="heading">Activate your account</h2>

                        <fieldset class="w-100 float-left">
                            <div class="w-100 float-left mb-3">
                                <p class="text">Your password needs to contain at least 8 characters, including one or more numbers</p>

                                <div class="field mb-0 w-100">
                                    <input class="input" formControlName="Password" id="password" maxlength="20" placeholder="Password" type="password">
                                    <div *ngIf="setPasswordForm.controls.Password.errors && (setPasswordForm.controls.Password.dirty || setPasswordForm.controls.Password.touched)" class="text-danger">
                                        <div *ngIf="setPasswordForm.controls.Password.errors.required" class="errorsize">
                                            *Please enter password.
                                        </div>

                                        <div *ngIf="setPasswordForm.controls.Password.errors?.minlength" class="errorsize">
                                            *Password must be contain atleast 8 character.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <div class="actions w-100 float-left">
                            <button (click)="setPassClick()" class="btn activate" type="submit">Activate your account</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
