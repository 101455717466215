export const environment = {
    production: false,
    app: {
        name: 'MTA Plus',
        env: 'staging',
        url: 'https://staging.mta-plus.co.uk/'
    },
    api: {
        url: 'https://mta-plus-staging-api.azurewebsites.net/api',
    },
    postcodeApi: {
        url: 'https://api.getAddress.io',
        key: 'oc2Ai1OAB0-nF9Y1ENlj8g14629'
    },
    xero: {
        returnUrl: 'https://staging.mta-plus.co.uk'
    },
    serverUrl: 'https://test.api.vulcan.hickwood.uk',
    storagePrefix: 'mta_plus',
    serviceWorkerEnabled: false,
    socketActive: false
};
