import {Component, OnInit}                  from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Title}                              from '@angular/platform-browser';
import {Router}                             from '@angular/router';
import {NgxSpinnerService}                  from 'ngx-spinner';
import {ToastrService}                      from 'ngx-toastr';
import {GlobalService}                      from '../../global.service';
import {LoginService}                       from '../../shared/services/login.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['../../login/login-background.scss']
})
export class ForgotPasswordComponent implements OnInit
{
    public id: string;
    public forgotForm: FormGroup = new FormGroup({
        email: new FormControl('', [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
    });

    public constructor(
        public toastr: ToastrService,
        private router: Router,
        private titleService: Title,
        private loginService: LoginService,
        private spinnerService: NgxSpinnerService,
        private globalService: GlobalService
    )
    {
        this.id = this.globalService.getEmail();
    }

    public ngOnInit(): void
    {
        this.titleService.setTitle('MTA Plus - ' + 'Forgotten Password');
    }

    public SetGlobalData(): void
    {
        this.id = this.forgotForm.controls.email.value;
        this.globalService.setEmail(this.id);
    }

    public SendLinkClick(): void
    {
        this.spinnerService.show();
        this.loginService.forgotpasswordsend(this.forgotForm.controls.email.value.trim()).subscribe(
            response => {
                this.spinnerService.hide();
                this.router.navigateByUrl('login');
                this.toastr.success('The link has been successfully sent to ' + this.forgotForm.controls.email.value.trim() + ', please click on the link inside the email and follow the instructions.', 'Email Sent');
            },
            error => {
                this.spinnerService.hide();
                this.toastr.error('Error sending link to the given email address.', 'Sending Error');
                this.loginService.handleError(error);
            }
        );
    }
}
