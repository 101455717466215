export class RegisterModel
{
Id:number;
CompanyName: string;
FirstName:string;
LastName: string;
EmailId:string;
ContactNumber: string;
RoleId:string;
}

