<section class="w-100 float-left bg-white">
  <header class="header w-100 float-left pt-2">
    <div class="logo">
      <a href="login"> <img src="../../assets/images/logo-icon.png" alt="logo" /></a>
    </div>
    <!--logo end-->
  </header>
  <div class="w-100 float-left">

    <div style="margin: 40px;">
      <h3 style="text-align: center;"><strong>We are currently confirming your email address.</strong></h3>

      <p style="text-align: center;">
        <img src="../../../assets/images/Ellipsis-1s-200px.svg" />
      </p>

      <p style="text-align: center;">
        Please wait one moment
      </p>
    </div>
  </div>
</section>