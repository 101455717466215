import {Component, Inject, OnInit}     from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment}                   from '../../../environments/environment';

@Component({
    selector: 'app-xero-connection-modal',
    templateUrl: './xero-connection-modal.component.html',
    styleUrls: ['./xero-connection-modal.component.scss']
})
export class XeroConnectionModalComponent implements OnInit
{

    constructor(
        public xeroConnectionModal: MatDialogRef<XeroConnectionModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
    )
    {

    }

    ngOnInit()
    {

    }

    connectToXero()
    {
        window.location.href = 'https://login.xero.com/identity/connect/authorize?response_type=code&client_id=A1FC34E3493A4B47AA10784401518D98&redirect_uri=' + environment.xero.returnUrl + '/layout/xero/success&scope=openid profile email offline_access accounting.contacts accounting.transactions accounting.settings';
    }

    onNoClick(res): void
    {
        this.xeroConnectionModal.close(res);
    }
}
